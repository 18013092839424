<template>
  <font-awesome-icon
    :icon="icon"
    class="icon"/>
</template>

<script>
export default {
  name: 'BaseIcon',
  props: {
    icon: {
      type: [String, Array],
      default: 'Button',
    },
  },
}
</script>
