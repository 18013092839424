<template>
  <div
    :class="{active: this.$store.state.generation}"
    class="filter filterGenerations"
    @click="$store.dispatch('closeFilters')"
  >
    <h3 class="title">Generation</h3>
    <div class="content">
      <div
        v-tooltip="`${generation.name} Generation<br>${generation.startYear} - ${generation.endYear}`"
        v-for="generation in generations"
        :key="generation.startYear"
        :class="['item', {active : generation.name == $store.state.generation}]"
        :style="`width: ${generation.percent}%`"
        @click="selectItem(generation.name)"
      >
        <div class="itemInner"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GenerationsFilter',
  computed: {
    generations() {
      return this.$store.state.generations
    },
  },
  created() {
    this.$store.dispatch('loadGenerations')
  },
  methods: {
    selectItem(gen) {
      this.$store.dispatch('setGeneration', gen)
    },
  },
}
</script>

<style lang="sass" scoped>
@import '~@assets/style/designs'

.title
  margin-bottom: $base-spacing
  font-size: 1.1rem
  font-weight: normal
  text-transform: uppercase
.content
  height: $filter-height
.item
  position: relative
  display: inline-block
  height: $filter-height
  cursor: pointer
.itemInner
  display: block
  width: 100%
  height: 100%
  opacity: 0.8
  transition: opacity $prim-trans-time $prim-trans-easing
  &::after
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: $filter-height / 2
    content: ""
    background: transparentize($c-black, 0.9)
  &:hover
    opacity: 1

.filter
  margin: 0 0 $base-spacing * 2 0
  &.active .item
    .itemInner
      opacity: 0.2
    &.active .itemInner
      opacity: 1

.filter .content .item
  &:first-child .itemInner, &:first-child::after
    border-top-left-radius: 20px
    border-bottom-left-radius: 20px
  &:last-child .itemInner, &:last-child::after
    border-top-right-radius: 20px
    border-bottom-right-radius: 20px
  &:nth-child(1) .itemInner
    background: #2797C9
  &:nth-child(2) .itemInner
    background:	#F0A519
  &:nth-child(3) .itemInner
    background: #EC4312
  &:nth-child(4) .itemInner
    background:	#7FB118
  &:nth-child(5) .itemInner
    background: #B74CC5
  &:nth-child(6) .itemInner
    background:	#E4DBCF
  &:nth-child(7) .itemInner
    background: #4556A7
  &:nth-child(8) .itemInner
    background:	#368994
  &:hover
    .tooltip
      z-index: 20
      opacity: 1
</style>
