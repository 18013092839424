<template>
  <div class="container">
    <div class="row">
      <div class="jar-xs-100">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayOneColumn',
}
</script>

<style scoped lang="sass">
@import '~@assets/style/designs'

</style>
