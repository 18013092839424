<script>
export default {
  name: 'FooterMenu',
  props: {
    menus: {
      type: Array,
      default: () => []
    },
  },
}
</script>

<template>
  <div class="text-xs-center">
    <ul
      v-for="(menu, index) in menus"
      :key="index">
      <li
        v-for="(menuItem, index) in menu.menuItems"
        :key="index"
        class="item">
        <router-link
          :to="menuItem.link"
        >{{ menuItem.name }}</router-link>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="sass">
@import '~@assets/style/designs'

ul
	display: block
	padding-left: $base-spacing
	margin: 0 auto
	text-align: center
	vertical-align: top
ul~ul
	margin-top: $base-spacing * 2
li
	margin: 0
	font-size: 1.1rem
	line-height: 1
	list-style: none
li~li
	margin-top: 10px

@media (min-width: $sm)
	ul
		border-left: 1px solid $text-muted
		display: inline-block
		text-align: left
	ul~ul
		margin-left: 60px
		margin-top: 0
	li
		position: relative
		a
			transition: color $prim-trans-time $prim-trans-easing
		&::after
			content: ""
			width: 13px
			height: 13px
			position: absolute
			top: 0
			bottom: 0
			margin: auto
			left: -27px
			vertical-align: middle
			border: 3px solid $c-acc-green
			border-radius: 50%
			opacity: 0
			transition: opacity $prim-trans-time $prim-trans-easing
		&:hover
			a
				color: $c-acc-green
			&::after
				opacity: 1
				transform: rotateY(1200deg)
				transition: opacity $prim-trans-time $prim-trans-easing, transform 5s linear
@media (min-width: $lg)
	ul~ul
		margin-left: 150px
</style>
