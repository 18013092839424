<template>
  <figure
    :style="`width:${getCircleSize*2+width*2}px`"
    class="progress">
    <svg
      v-if="size"
      :viewbox="[0, 0, getCircleSize*2+width, getCircleSize*2+width]"
      :width="getCircleSize*2+width*2"
      :height="getCircleSize*2+width*2"
      class="circleChart"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        :r="getCircleSize"
        :cx="getCircleSize+width"
        :cy="getCircleSize+width"
        :stroke-width="width"
        :class="['circleChartBackground', theme]"
        fill="none"
      />
      <circle
        :r="getCircleSize"
        :cx="getCircleSize+width"
        :cy="getCircleSize+width"
        :stroke-dasharray="[val, size]"
        :stroke-width="width"
        :class="['circleChartSlide', theme]"
        fill="none"
      />
    </svg>
    <div class="content">{{ currentVal | counting }}</div>
    <h3 class="title">{{ title }}</h3>
  </figure>
</template>


<script>
export default {
  name: 'Progress',
  props: {
    currentVal: {
      default: 0,
      type: Number,
    },
    maxVal: {
      default: 100,
      type: Number,
    },
    theme: {
      default: 'primaryGreen',
      type: String,
    },
    size: {
      default: 300,
      type: Number,
    },
    width: {
      default: 10,
      type: Number,
    },
    title: {
      default: 'Title',
      type: String,
    },
  },

  computed: {
    val() {
      let ratio = this.size / this.maxVal
      return this.currentVal * ratio
    },
    getCircleSize() {
      return this.size / (3.14 * 2)
    },
  },
}
</script>

<style lang="sass" scoped>
@import '~@assets/style/designs'

.progress
  position: relative
  display: inline-block

.circleChart
  transform: rotateZ(-90deg)
  &Background
    fill: $c-ebony-gray
    stroke: $c-ebony-gray
  &Slide
    animation: dash 2s ease-out forwards
    &.primaryGreen
      stroke: $c-acc-blue

.title
  text-align: center

.content
  position: absolute
  top: 48px
  right: 0
  left: 0
  margin: 0 auto
  font-size: 30px
  font-weight: bold
  text-align: center

@keyframes dash
  from
    stroke-dasharray: 0

</style>
