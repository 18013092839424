var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mainMenu" },
    [
      _c("MainMenuButton", {
        directives: [
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.navClose,
            expression: "navClose"
          }
        ],
        on: {
          menuBtnClicked: function($event) {
            return _vm.toggleNav()
          }
        }
      }),
      _c("div", { class: ["menuContainer", { active: _vm.isNavActive }] }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "menuContainerInner" }, [
            _c("div", { staticClass: "menuContent" }, [
              _c("div", { staticClass: "left side" }, [
                _c(
                  "ul",
                  { staticClass: "groupList" },
                  [
                    _vm._l(_vm.consoleCats, function(category) {
                      return _c(
                        "li",
                        { key: "cat" + category.id, staticClass: "item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "link",
                              attrs: {
                                to: {
                                  name: "consoles",
                                  params: { cat: category.alias }
                                }
                              }
                            },
                            [_vm._v(_vm._s(category.name))]
                          )
                        ],
                        1
                      )
                    }),
                    _c(
                      "li",
                      { staticClass: "item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "link",
                            attrs: {
                              to: {
                                name: "accessories",
                                params: { cat: "controllers" }
                              }
                            }
                          },
                          [_vm._v("Accessories")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "li",
                      { staticClass: "item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "link",
                            attrs: {
                              to: {
                                name: "list-item",
                                params: {
                                  type: "consoles",
                                  characteristic: "educational",
                                  title: "Educational Consoles"
                                }
                              }
                            }
                          },
                          [_vm._v("Educational")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "li",
                      { staticClass: "item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "link",
                            attrs: {
                              to: {
                                name: "list-item",
                                params: {
                                  type: "consoles",
                                  characteristic: "retro",
                                  title: "Retro Consoles"
                                }
                              }
                            }
                          },
                          [_vm._v("Retro")]
                        )
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "sliderContainer" },
                  [_c("MainMenuSlider")],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "center" },
                [
                  _c("BaseImage", {
                    attrs: { source: _vm.highlight, alt: "Test Image" }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "right side" }, [
                _c("ul", { staticClass: "groupList" }, [
                  _c(
                    "li",
                    { staticClass: "item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "link",
                          attrs: {
                            to: {
                              name: "posts",
                              params: { cat: "gaming-console-news" }
                            }
                          }
                        },
                        [_vm._v("Console News")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "link",
                          attrs: {
                            to: {
                              name: "posts",
                              params: { cat: "gaming-console-history" }
                            }
                          }
                        },
                        [_vm._v("Console History")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "link",
                          attrs: {
                            to: {
                              name: "posts",
                              params: { cat: "gaming-news" }
                            }
                          }
                        },
                        [_vm._v("Gaming News")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "link",
                          attrs: {
                            to: { name: "posts", params: { cat: "how-to" } }
                          }
                        },
                        [_vm._v("How To")]
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }