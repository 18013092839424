<template>
  <div :class="['sectionTitle', iconAccent]">
    <h2>
      <span
        v-if="icon"
        class="icon"
        v-html="icon"/>
      <span v-html="'&nbsp'"/>
      <span
        class="title"
        v-html="title"/>
    </h2>
  </div>
</template>

<script>
export default {
  name: 'BaseSectionTitle',
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    icon: {
      type: String,
      default: '',
    },
    iconAccent: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="sass" scoped>
@import '~@assets/style/designs'

.icon
  font-size: 30px
  vertical-align: middle
.title
  vertical-align: middle
h2
  margin: 0

  @extend %heading

.sectionTitle
  text-transform: uppercase
  &.pink
    .icon
      color: $c-acc-pink
  &.green
    .icon
      color: $c-acc-green
  &.blue
    .icon
      color: $c-acc-blue
</style>
