
<script>
import LayOneColumn from '@layouts/LayOneColumn'

export default {
  name: 'InfoPage',
  page() {
    return {
      title: `${this.title || 'Page'} not Found`,
      meta: [
        { name: 'description', content: `Everybody makes mistakes. You can always go back home or take the red pill and try once more something random.` },
      ],
    }
  },
  components: {
    LayOneColumn,
  },
  props: {
    message: {
      type: String,
      default: 'Please try again later',
    },
    title: {
      type: String,
      default: 'Page'
    },
    link: {
      type: String,
      default: ''
    }
  },
}
</script>

<template>
  <div class="pageNotFound page">
    <LayOneColumn>
      <div class="content">
        <h1 class="text-xs-center">404 - {{ title }} Not Found</h1>
        <BaseIcon
          :icon="'flag'"
          class="icon iconFlag"/>
        <h3 class="message">{{ message }}</h3>
        <div class="buttons">
          <BaseButton
            link="/"
            text="Home"
            class="button accentGreenBlue"/>
          <BaseButton
            v-if="link"
            :text="title"
            :link="link"
            class="button accentGreenBlue"/>
        </div>
      </div>
    </LayOneColumn>
  </div>
</template>

<style lang="sass" scoped>
@import '~@assets/style/designs'

.content
  text-align: center
.icon
  &Flag
    margin-bottom: $base-spacing
    font-size: 60px
.buttons
  display: grid
  grid-template-columns: min-content min-content
  column-gap: $base-spacing
  align-content: center
  justify-content: center
  width: 100%
  margin-top: $section-spacing


</style>
