<script>
export default {
  name: 'BaseLabel',
  props: {
    float: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'top left outset' // Available: 'top', 'right', 'bottom', 'left',, 'outset' and any logical combination
    },
    size: {
      type: String,
      default: 'small' // Availabe: 'small', 'medium'
    },
    color: {
      type: String,
      default: 'green' // Available: 'green', 'pink' ,'blue', 'gradientGreen', 'gradientBlue', 'gradientPink', 'rainbow'
    },
    shape: {
      type: String,
      default: '' // Available: '', 'circle'
    },
    spacing: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>
  <div
    :style="`${spacing && `margin: ${spacing}`}`"
    :class="['label', float && 'float', float && position, size, color, shape && shape]">
    <span class="labelText"><slot/></span>
  </div>
</template>

<style lang="sass" scoped>
@import '~@assets/style/designs'

$outset-spacing: $base-spacing / 2

.label
  z-index: 10
  display: inline-block
  margin: 0
  color: $c-white
  border-radius: 15px
  box-shadow: $base-shadow
  &Text
    font-weight: bold
  &.float
    position: absolute

  // Sizes
  &.small
    padding: 3px 9px
    font-size: 9px
  &.medium
    padding: $base-spacing / 3 $base-spacing


  // Positions
  &.top
    top: 0
    bottom: unset
    &.outset
      top: -$outset-spacing
    &.inset
      top: $outset-spacing
  &.right
    right: 0
    left: unset
    &.outset
      right: -$outset-spacing
    &.inset
      right: $outset-spacing
  &.bottom
    top: unset
    bottom: 0
    &.outset
      bottom: -$outset-spacing
    &.inset
      bottom: $outset-spacing
  &.left
    right: unset
    left: 0
    &.outset
      left: -$outset-spacing
    &.inset
      left: $outset-spacing

  // Colors
  &.green
    background: $c-acc-green
  &.pink
    background: $c-acc-pink
  &.blue
    color: $c-ebony-clay-gray
    background: $c-acc-blue
  &.gradientGreen
    color: $c-ebony-clay-gray
    background: $green-grey-gradient
  &.gradientBlue
    background: $green-blue-purple-gradient
  &.gradientPink
    background: $pink-blue-gradient
  &.rainbow
    background: $rainbow-gradient

  // Shape
  &.circle
    padding: 0
    vertical-align: middle
    border-radius: 50%
    &.small
      width: 40px
      height: 40px
      line-height: 40px
</style>
