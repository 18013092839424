import { render, staticRenderFns } from "./FooterAbout.vue?vue&type=template&id=44ac5bb2&scoped=true&"
import script from "./FooterAbout.vue?vue&type=script&lang=js&"
export * from "./FooterAbout.vue?vue&type=script&lang=js&"
import style0 from "./FooterAbout.vue?vue&type=style&index=0&id=44ac5bb2&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44ac5bb2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/pmanikas.social/consall/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('44ac5bb2')) {
      api.createRecord('44ac5bb2', component.options)
    } else {
      api.reload('44ac5bb2', component.options)
    }
    module.hot.accept("./FooterAbout.vue?vue&type=template&id=44ac5bb2&scoped=true&", function () {
      api.rerender('44ac5bb2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/FooterAbout.vue"
export default component.exports