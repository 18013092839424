<template>
  <div
    :class="['menuBtn', { active : $store.state.isNavOpen }]"
    @click="$emit('menuBtnClicked')">
    <div class="menuBtnContainer">
      <div class="buttonGroup">
        <span class="button"/>
        <span class="button"/>
      </div>
      <div class="buttonGroup">
        <span class="button"/>
        <span class="button"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainMenuButton',
}
</script>

<style scoped lang="sass">
@import '~@assets/style/designs'

$trans-opacity: opacity .3s ease-in-out
$trans-transform: transform .3s ease-in-out

.buttonGroup
  position: relative
  width: 50px
  padding: 3px 0
  margin: 0 auto
  &::before
    position: absolute
    top: 0
    left: 0
    display: block
    width: 100%
    height: 100%
    content: ''
    background: transparentize($c-alto-grey, 0.5)
    border-radius: 20px
    opacity: 0
    transition: $trans-opacity
.buttonGroup~.buttonGroup
  margin-top: 5px

.button
  position: relative
  display: inline-block
  width: 15px
  height: 15px
  vertical-align: middle
  background: white
  border-radius: 50%
  &::before, &::after
    position: absolute
    top: 0
    left: 0
    display: block
    width: 100%
    height: 100%
    content: ''
    border-radius: 50%
    opacity: 0
    transition: $trans-opacity
.button~.button
  margin-left: 10px

.menuBtnContainer
  position: relative
  display: inline-block
  width: $header-height


.buttonGroup:nth-of-type(1)
  .button::before
    background: $c-kashmir-blue
  .button:nth-of-type(1)
    &::after
      background: $c-ocean-green
  .button:nth-of-type(2)
    &::after
      background: $c-bondi-blue

.buttonGroup:nth-of-type(2)
  .button::before
    background: $c-affair-purple
  .button:nth-of-type(1)
    &::after
      background: $c-candlelight-yellow
  .button:nth-of-type(2)
    &::after
      background: $c-sunglo-red


.menuBtn
  position: relative
  left: -20%
  z-index: 101
  display: flex
  align-items: center
  width: $header-height
  height: $header-height
  text-align: center
  cursor: pointer
  transition: $trans-transform
  transform: rotate(0) scale(0.5)
  &::before
    position: absolute
    top: 0
    left: 0
    display: block
    width: 100%
    height: 100%
    content: ''
    background: transparentize($c-boulder-grey, 0.5)
    border-radius: 50%
    opacity: 0
    transition: $trans-opacity
  &:hover
    transform: rotate(0) scale(0.9)
    &::before
      opacity: 1
    .buttonGroup
      &::before
        opacity: 1
      .button::before
        opacity: 1
  &.active
    transform: rotate(-45deg) scale(0.9)
    &::before
      opacity: 1
    .buttonGroup
      &::before
        opacity: 1
      .button::after
        opacity: 1
</style>
