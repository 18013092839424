var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.background
    ? _c("div", {
        staticClass: "background",
        style: "background-image: url('" + _vm.source + "')"
      })
    : !_vm.background && _vm.source
    ? _c(
        "figure",
        {
          directives: [{ name: "lazyload", rawName: "v-lazyload" }],
          key: _vm.source,
          class: ["wrap"]
        },
        [
          _c("div", { staticClass: "spinner" }),
          _c("img", {
            staticClass: "item",
            attrs: { "data-url": _vm.source, alt: _vm.alt }
          })
        ]
      )
    : _c(
        "div",
        { staticClass: "noImage" },
        [
          _c("BaseIcon", {
            staticClass: "noImageIcon",
            attrs: { icon: "image" }
          }),
          _c("BaseLabel", { staticClass: "label", attrs: { float: "" } }, [
            _vm._v(_vm._s(_vm.customText || "NO IMAGE"))
          ])
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }