<template>
  <div class="page faq">
    <LayOneColumn>
      <h1 class="text-xs-center">FAQ</h1>
      <div class="tabs">
        <div
          v-for="faq in faqs"
          :key="faq.index"
          class="tab">
          <h2 class="itemTitle">{{ faq.name }}</h2>
          <div v-html="faq.descr"/>
        </div>
      </div>
    </LayOneColumn>
  </div>
</template>

<script>
import LayOneColumn from '@layouts/LayOneColumn'

export default {
  name: 'FaqPage',
  meta() {
    return {
      title: 'FAQ',
      meta: [
        { name: 'description', content: 'Find the most common questions you may have.' },
      ],
    }
  },
  components: {
    LayOneColumn,
  },
  computed: {
    faqs() {
      return this.$store.state.faqs
    },
  },
  created() {
    this.$store.dispatch('loadFaqs')
  },
}
</script>

<style scoped lang="sass">
@import '~@assets/style/designs'

.tab
  position: relative
  padding: $base-spacing * 2 $base-spacing * 4
  margin-bottom: 30px
  color: $c-black
  background: $text-muted
  border-radius: 2px
  box-shadow: 0 0 10 0 black
  &::after
    position: absolute
    top: 0
    right: 0
    width: 30px
    height: 100%
    content: ""
    background: $c-acc-green
    box-shadow: 0 0 5px 0 black
    transition: width $prim-trans-time $prim-trans-easing
  &:nth-of-type(3n + 1)
    &::after
      background: $c-acc-red
  &:nth-of-type(3n + 2)
    &::after
      background: $c-acc-blue
  &:hover
    &::after
      width: 50px
.itemTitle
  margin-bottom: $base-spacing
  color: $bg-color
</style>
