<template>
  <div :class="['socialMedia', { header : header }]">
    <ul>
      <li
        v-for="(social, index) in socials"
        :key="index"
        class="item">
        <a
          :href="social.link"
          :title="social.name"
          class="link">
          <BaseIcon :icon="['fab', social.icon]"/>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SocialMedia',
  props: {
    header: {
      type: Boolean,
      default: () => {
        return false
      },
    },
  },
  computed: {
    socials() {
      return this.$store.state.socials
    },
  },
  created() {
    this.$store.dispatch('loadSocials')
  },
}
</script>

<style scoped lang="sass">
@import '~@assets/style/designs'

.socialMedia
  margin-top: $base-spacing * 2
.link
  transition: color $prim-trans-time $prim-trans-easing
.item
  display: inline-block
  &:hover
    .link
      color: $c-acc-green
.item~.item
  margin-left: $base-spacing
.socialMedia.header
  .item
    height: $header-height
    padding-right: $base-spacing
    line-height: $header-height


@media (min-width: $md)
  .socialMedia
    margin-top: 0
</style>
