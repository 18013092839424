<script>
import LayOneColumn from '@layouts/LayOneColumn'
import { get } from '@services/apiUtils'

export default {
  name: 'InfoPage',
  meta() {
    return {
      title: this.currentPage.name,
      meta: [
        { name: 'description', content: this.currentPage.description || 'Everything About Gaming Consoles' },
        { name: `${this.currentPage && this.currentPage.seo && this.currentPage.seo.indexing.length && 'robots' || 'hakuna-matata'}`, content: `${this.currentPage && this.currentPage.seo && this.currentPage.seo.indexing || ''}` }
      ],
    }
  },
  components: {
    LayOneColumn,
  },
  data() {
    return {
      pageDataApi: {}
    }
  },
  computed: {
    currentPage() {
      return this.pageDataApi
    },
    alias() {
      return this.$route.params.name
    }
  },
  watch: {
    alias() {
      this.get()
    },
  },
  created() {
    this.get()
  },
  methods: {
    get() {
      get(`${process.env.VUE_APP_API_BASE_URL}/page/${this.alias}/`)
        .then(body => {
          this.pageDataApi = body.data
        })
        .catch(_error => {
          this.$router.push({name: 404})
        })
    }
  }
}
</script>

<template>
  <div :class="['page', $route.params.name]">
    <LayOneColumn>
      <h1 class="text-xs-center">{{ currentPage.name }}</h1>
      <div v-html="currentPage.descr"/>
    </LayOneColumn>
  </div>
</template>
