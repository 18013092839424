<template>
  <div class="categoryContent page">
    <LayWithSidebar>
      <div slot="sidebar">
        <filterPanel :title="'Accessories'">
          <categoriesFilter
            :cats="categories"
            :type="'accessories'"/>
          <GenericFilter
            :items="$store.state.brands"
            :selected-item="$store.state.accessoriesBrand || 'all'"
            :title="'Brands'"
            :setting="'setAccessoriesBrand'"
          />
        </filterPanel>
      </div>
      <div slot="content">
        <CategoryPageContent
          :is-filter="true"
          :items="items"/>
      </div>
    </LayWithSidebar>
  </div>
</template>

<script>
import LayWithSidebar from '@layouts/LayWithSidebar'
import filterPanel from '@partials/FiltersPanel'
import categoriesFilter from '@components/FiltersItems/CategoriesFilter'
import GenericFilter from '@components/FiltersItems/GenericFilter'
import CategoryPageContent from '@partials/CategoryPageContent'

export default {
  name: 'AccessoriesCategoryPage',
  meta() {
    return {
      title: ` ${this.title || ''} Accessories Category List`,
      meta: [
        { name: 'description', content: `Browse and find the gaming console ${this.title || 'accessories'} you are looking for.` },
      ],
    }
  },
  components: {
    LayWithSidebar,
    filterPanel,
    categoriesFilter,
    GenericFilter,
    CategoryPageContent,
  },
  computed: {
    items() {
      return this.$store.getters.accessoriesFilteredByAll
    },
    categories() {
      return this.$store.state.accessoriesCats
    },
    title() {
      return this.$route.params.cat.replace('-', ' ').replace(/^\w/, c => c.toUpperCase())
    }
  },
  watch: {
    $route() {
      this.updateStorageAttrs()
    },
  },
  created() {
    this.$store.dispatch('loadAccessoriesCats')
    this.$store.dispatch('loadAccessories')
    this.$store.dispatch('loadBrands')
    this.updateStorageAttrs()
  },
  methods: {
    updateStorageAttrs() {
      let catParam = this.$route.params.cat || ''
      this.$store.dispatch('setAccessoriesCategory', catParam)
    },
  },
}
</script>

<style scoped lang="sass">
@import '~@assets/style/designs'

</style>
