var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "footerSearch text-xs-center",
      class: { active: _vm.searchFocused }
    },
    [
      _c("div", { staticClass: "searchInput", class: { enter: _vm.enter } }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchText,
              expression: "searchText"
            }
          ],
          ref: "footerSearchInput",
          attrs: { id: "footerSearch", type: "text" },
          domProps: { value: _vm.searchText },
          on: {
            focus: function($event) {
              _vm.searchFocused = true
            },
            blur: function($event) {
              _vm.searchFocused = false
            },
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.searchText = $event.target.value
              },
              _vm.enableEnter
            ],
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.enterSearch($event)
            }
          }
        }),
        _c(
          "div",
          { staticClass: "enterIcon", on: { click: _vm.enterSearch } },
          [_vm._v("Enter")]
        )
      ]),
      _c(
        "label",
        { attrs: { for: "footerSearch" } },
        [
          _c("BaseIcon", {
            class: ["searchIcon", { active: _vm.iconShown }],
            attrs: { icon: "search" }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }