<template>
  <div
    v-click-outside="closeFilters"
    :class="{active: $store.state.isFilterOpen}"
    class="sidebar">
    <div @click="toggleFilters">
      <BaseIcon
        class="openFiltersBtn"
        icon="chevron-down"/>
    </div>
    <h2 class="text-xs-center title">{{ title }}</h2>
    <slot/>
    <div
      class="text-xs-right"
      @click="clearFilters()">
      <BaseButton
        :text="'CLEAR'"
        class="clearBtn"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterPanel',
  props: {
    title: {
      type: String,
      default: 'Filters',
    },
  },
  methods: {
    toggleFilters() {
      this.$store.dispatch('toggleFilters')
    },
    closeFilters() {
      this.$store.dispatch('closeFilters')
    },
    clearFilters() {
      this.$store.dispatch('clearFilters')
    },
  },
}
</script>

<style scoped lang="sass">
@import '~@assets/style/designs'

.openFiltersBtn
  position: absolute
  right: 0
  bottom: 10px
  left: 0
  z-index: 20
  margin: 0 auto
  font-size: 16px
  color: $c-acc-green
  transition: transform $prim-trans-time $prim-trans-easing
  transform: scaleX(1.7)
.sidebar
  position: relative
  width: 100%
  max-height: 175px
  padding: $base-spacing
  // margin-top: $section-margin-top
  overflow: hidden
  background: $bg-color
  box-shadow: $base-shadow
  transition: max-height $prim-trans-time $prim-trans-easing
  &.active
    max-height: 1000px
    .openFiltersBtn
      transform: scaleX(1.7) rotate(180deg)
  &::after
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 1px
    content: ""
    background: $rainbow-gradient
.title
  margin-bottom: $base-spacing
.filterItem
  margin: 0 0 ($base-spacing * 2) 0

.clearBtn
  cursor: pointer
  border-radius: 20px
  span
    padding: 7.5px 20px

@media (min-width: $md)
  .sidebar
    max-height: none
    overflow: unset
    &::after
      display: none
    .openFiltersBtn
      display: none
</style>
