<template>
  <div class="logo">
    <router-link to="/">
      <!-- <img
        :src="logoPath"
      alt="Logo">-->
      <span class="logoText">{{ logoText }}</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  data() {
    return {
      logoText: 'CONSALL.EU',
      logoPath: require('@assets/images/consall-logo.png'),
    }
  },
}
</script>

<style scoped lang="sass">
@import '~@assets/style/designs'

.logo
  display: block
.logoText
  font-family: $font-family-heading
  font-size: 1.5rem
  font-weight: bold
  line-height: $header-height
  color: $c-alto-grey
  vertical-align: middle
img
  height: 80px
</style>
