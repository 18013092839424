<script>
import ListItem from '@components/ListItem'

export default {
  name: 'CategoryPageContent',
  components: {
    ListItem,
  },
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
    isFilter: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clearFilters() {
      this.$store.dispatch('clearFilters')
    },
  },
}
</script>

<template>
  <div class="categoryContent">
    <div class="row halfSpacing">
      <div v-if="items.length > 0">
        <transition-group
          name="fade"
          tag="div">
          <ListItem
            v-for="item in items"
            :key="item.id"
            :item="item"
            class="jar-sm-50 jar-md-33 jar-xl-20 item"
          />
        </transition-group>
      </div>
      <div v-else>
        <div v-if="isFilter">
          <h1 class="text-xs-center">Try another filter criteria or
            <span
              class="clearText"
              @click="clearFilters()">clear</span> filter
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass">
@import '~@assets/style/designs'

.halfSpacing
  margin-right: -10px
  margin-left: -10px
.item
  padding-right: 10px
  padding-left: 10px
.clearText
  color: $c-acc-red
  cursor: pointer

// Animations
.fade-enter-active, .fade-leave-active
  transition: all 0.5s ease-in
.fade-enter, .fade-leave-to
  opacity: 0
  transform: scale(0)
</style>
