<template>
  <div
    class="toTop"
    @click="scrollTo(0,null,'smooth')">
    <BaseIcon
      class="toTopIcon"
      icon="long-arrow-alt-up"/>
  </div>
</template>

<script>
import scrollTo from '@mixins/ScrollTo'

export default {
  name: 'BaseToTop',
  mixins: [scrollTo],
}
</script>

<style scoped lang="sass">
@import '~@assets/style/designs'

.toTop
	padding: 30px 0
	cursor: pointer
	.toTopIcon
		font-size: 40px
	&:hover
		animation: neck .5s infinite ease-in alternate

@keyframes neck
	0%
		transform: scaleY(1) translateY(0)
	70%
		transform: scale(1.1) translateY(-1px)
	100%
		transform: scaleY(1.3) translateY(-3px)
</style>
