<template>
  <div class="search page">
    <LayOneColumn>
      <h1 class="text-xs-center">Search Results for
        <span class="searchInput">{{ $route.params.input }}</span>
      </h1>
      <div
        v-if="consolesFilteredByKeyword && consolesFilteredByKeyword.length > 0"
        class="row searchResults"
      >
        <div class="jar-xs-100">
          <h2 class="searchSectionTitle">Consoles</h2>
        </div>
        <div class="jar-xs-100">
          <div class="row">
            <ListItem
              v-for="(item, index) in consolesFilteredByKeyword"
              v-if="index < (isConsoleLoadAllEnabled ? consolesFilteredByKeyword.length : maxInitItems)"
              :key="item.cat+item.id"
              :item="item"
              class="jar-sm-50 jar-md-25 item"
            />
          </div>
        </div>
        <div
          v-if="!isConsoleLoadAllEnabled && maxInitItems < consolesFilteredByKeyword.length"
          class="jar-xs-100"
          @click="isConsoleLoadAllEnabled = true"
        >
          <BaseButton
            :text="'Load All'"
            class="accentGreenBlue"/>
        </div>
      </div>
      <div
        v-if="accessoriesFilteredByKeyword && accessoriesFilteredByKeyword.length > 0"
        class="row searchResults"
      >
        <div class="jar-xs-100">
          <h2 class="searchSectionTitle">Accessories</h2>
        </div>
        <div class="jar-xs-100">
          <div class="row">
            <ListItem
              v-for="(item, index) in accessoriesFilteredByKeyword"
              v-if="index < (isAccessoriesLoadAllEnabled ? accessoriesFilteredByKeyword.length : maxInitItems)"
              :key="item.cat+item.id"
              :item="item"
              class="jar-sm-50 jar-md-25 item"
            />
          </div>
        </div>
        <div
          v-if="!isAccessoriesLoadAllEnabled && maxInitItems < accessoriesFilteredByKeyword.length"
          class="jar-xs-100"
          @click="isAccessoriesLoadAllEnabled = true"
        >
          <BaseButton
            :text="'Load All'"
            class="accentGreenBlue"/>
        </div>
      </div>
      <div class="row">
        <div class="jar-xs-100">
          <h2
            v-if="$route.params.input.length < 4"
            class="text-xs-center"
          >You have to enter at least 4 characters</h2>
          <h2
            v-if="consolesFilteredByKeyword.length === 0 && accessoriesFilteredByKeyword.length === 0 && $route.params.input.length > 3"
            class="text-xs-center"
          >No results found</h2>
        </div>
      </div>
    </LayOneColumn>
  </div>
</template>

<script>
import LayOneColumn from '@layouts/LayOneColumn'
import ListItem from '@components/ListItem'

export default {
  name: 'SearchPage',
  meta() {
    return {
      title: `Search results for '${this.searchInput || 'anything you need'}'`,
      meta: [
        { name: 'description', content: `Just hit the word on the search field and find anything you need regarding ${this.searchInput || 'consoles, accessories, gaming articles and news'}` },
      ],
    }
  },
  components: {
    LayOneColumn,
    ListItem,
  },
  data() {
    return {
      maxInitItems: 4,
      isConsoleLoadAllEnabled: false,
      isAccessoriesLoadAllEnabled: false,
    }
  },
  computed: {
    consolesFilteredByKeyword() {
      const searchParam = this.$route.params.input
      if (searchParam.length > 3) {
        return this.$store.getters.consolesFilteredByKeyword
      }
    },
    accessoriesFilteredByKeyword() {
      const searchParam = this.$route.params.input
      if (searchParam.length > 3) {
        return this.$store.getters.accessoriesFilteredByKeyword
      }
    },
    searchInput() {
      return this.$route.params.input.replace('-', ' ').replace(/^\w/, c => c.toUpperCase())
    }
  },
  watch: {
    $route() {
      this.$store.dispatch('setKeyword', this.$route.params.input)
    },
  },
  created() {
    this.$store.dispatch('loadAccessories')
    this.$store.dispatch('setKeyword', this.$route.params.input)
  },
  destroyed() {
    this.$store.dispatch('setKeyword', '')
  },
}
</script>

<style lang="sass" scoped>
@import '~@assets/style/designs'

.searchInput
  font-style: italic
  font-weight: normal
  color: $c-acc-green
.searchResults
  margin-bottom: $section-margin-bottom / 2
.searchSectionTitle
  margin-bottom: $headings-margin / 2
</style>
