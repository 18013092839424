var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { staticClass: "page postPage" },
    [
      _c(
        "LayOneColumn",
        [
          _c("div", { staticClass: "postTop" }, [
            _c("header", { staticClass: "postTitleContainer" }, [
              _c("h1", { staticClass: "text-xs-center postTitle" }, [
                _vm._v(_vm._s(_vm.post.name))
              ])
            ]),
            _c("div", { staticClass: "postDetails" }, [
              _c(
                "div",
                { staticClass: "postCategories" },
                _vm._l(_vm.post.cat, function(category, i) {
                  return _c(
                    "router-link",
                    {
                      key: category + i,
                      attrs: {
                        to: { name: "posts", params: { cat: category.alias } }
                      }
                    },
                    [
                      _c("BaseLabel", { attrs: { size: "medium" } }, [
                        _vm._v(_vm._s(category.name))
                      ])
                    ],
                    1
                  )
                }),
                1
              ),
              _c("div", { staticClass: "postAuthor" }, [
                _vm._v("Written by "),
                _c("span", { staticClass: "postAuthorName" }, [
                  _vm._v(
                    _vm._s(
                      _vm.post && _vm.post.author && _vm.post.author.username
                    )
                  )
                ])
              ])
            ])
          ]),
          _c("BaseImage", {
            staticClass: "postImage",
            attrs: {
              source: _vm.post && _vm.post.photo && _vm.post.photo.large,
              alt: _vm.post && _vm.post.photo && _vm.post.photo.alt
            }
          }),
          _c("div", {
            staticClass: "postDescription",
            domProps: { innerHTML: _vm._s(_vm.post && _vm.post.description) }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }