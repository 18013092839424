<template>
  <div class="mainMenu">
    <MainMenuButton
      v-click-outside="navClose"
      @menuBtnClicked="toggleNav()"/>
    <div :class="['menuContainer', {active : isNavActive}]">
      <div class="container">
        <div
          class="menuContainerInner">
          <div class="menuContent">
            <div class="left side">
              <ul class="groupList">
                <li
                  v-for="category in consoleCats"
                  :key="'cat'+category.id"
                  class="item">
                  <router-link
                    :to="{ name: 'consoles', params:{ cat: category.alias }}"
                    class="link"
                  >{{ category.name }}</router-link>
                </li>
                <li class="item">
                  <router-link
                    :to="{ name: 'accessories', params: {cat: 'controllers'} }"
                    class="link"
                  >Accessories</router-link>
                </li>
                <li class="item">
                  <router-link
                    :to="{ name: 'list-item', params: { type: 'consoles', characteristic: 'educational', title: 'Educational Consoles' } }"
                    class="link"
                  >Educational</router-link>
                </li>
                <li class="item">
                  <router-link
                    :to="{ name: 'list-item', params: { type: 'consoles', characteristic: 'retro', title: 'Retro Consoles' } }"
                    class="link"
                  >Retro</router-link>
                </li>
              </ul>
              <div class="sliderContainer">
                <MainMenuSlider/>
              </div>
            </div>
            <div class="center">
              <BaseImage
                :source="highlight"
                alt="Test Image"/>
            </div>
            <div class="right side">
              <ul class="groupList">
                <li class="item">
                  <router-link
                    :to="{ name: 'posts', params: { cat: 'gaming-console-news' } }"
                    class="link">Console News</router-link>
                </li>
                <li class="item">
                  <router-link
                    :to="{ name: 'posts', params: { cat: 'gaming-console-history' } }"
                    class="link"
                  >Console History</router-link>
                </li>
                <li class="item">
                  <router-link
                    :to="{ name: 'posts', params: { cat: 'gaming-news' } }"
                    class="link"
                  >Gaming News</router-link>
                </li>
                <li class="item">
                  <router-link
                    :to="{ name: 'posts', params: { cat: 'how-to' } }"
                    class="link"
                  >How To</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import scrollTo from '@mixins/ScrollTo'
import MainMenuButton from '@components/MainMenuButton'
import MainMenuSlider from '@components/MainMenuSlider'
import highlight from '@assets/images/nintendo-game-cube-scheme.jpg'

export default {
  name: 'MainMenu',
  components: {
    MainMenuButton,
    MainMenuSlider,
  },
  mixins: [scrollTo],
  data() {
    return {
      highlight: highlight,
    }
  },
  computed: {
    isNavActive() {
      return this.$store.state.isNavOpen
    },
    consoleCats() {
      return this.$store.state.consoleCats
    },
  },
  watch: {
    $route() {
      this.navClose()
    },
  },
  created() {
    document.onkeydown = evt => {
      evt = evt || window.event
      if (evt.keyCode === 27) {
        this.navClose()
      }
    }
  },
  methods: {
    toggleNav() {
      this.$store.dispatch('toggleNav')
    },
    navClose() {
      this.$store.dispatch('closeNav')
    }
  }
}
</script>

<style scoped lang="sass">
@import '~@assets/style/designs'
$border-color: transparentize($c-ebony-clay-gray, 0.8)

.mainMenu
  position: relative

.menuContainer
  position: fixed
  top: 0
  left: 0
  z-index: 100
  width: 100%
  background: transparentize($c-ocean-green, 0.7)
  transition: transform .3s ease-in-out
  transform: translateY(-100%)
  &.active
    transform: translateY(0)

.container
    padding: 0

.menuContainerInner
  height: 100vh
  padding: $header-height 0 0
  background: transparentize($c-ocean-green, 0.1)

.menuContent
  display: flex
  flex-direction: column
  justify-content: space-between

.side
  position: relative
  flex: 3

.center
  position: relative
  z-index: 0
  display: none
  flex: 2
  height: calc(100vh - #{$header-height})
  text-align: center
  background: $c-ebony-clay-gray
  border-right: 1px solid $border-color
  border-left: 1px solid $border-color
  img
    max-width: 100%
    max-height: 100%
    padding: $base-spacing 0
    opacity: 0.5

.groupList
  display: flex
  flex-wrap: wrap
  margin-bottom: 0
  border-top: 1px solid $border-color
  border-bottom: 1px solid $border-color

.right.side .groupList
  margin-top: $base-spacing

.link
  position: relative
  z-index: 10
  display: block
  padding: 0 $base-spacing
  font-family: $font-family-heading
  color: $c-white
  &::after
    position: absolute
    top: 0
    left: 0
    z-index: 0
    display: block
    width: 100%
    height: 100%
    content: ''
    background: $border-color
    transition: transform .3s ease-in-out
    transform: translateY(100%)
  /* stylelint-disable selector-class-pattern */
  &.router-link-exact-active
    &::after
      color: $c-acc-blue
      background: $border-color
      transform: translateY(0)
  /* stylelint-enable */

.item
  position: relative
  display: inline-block
  flex-basis: 50%
  height: $menu-item-height
  margin: 0
  overflow: hidden
  font-size: 1rem
  font-weight: bold
  line-height: $menu-item-height
  &::after
    position: absolute
    top: 0
    left: 0
    z-index: 0
    display: block
    width: 100%
    height: 100%
    content: ''
    background: $border-color
    transition: transform .3s ease-in-out
    transform: translateY(100%)
  &:hover
    .link::after
      transform: translateY(0)

.item:nth-of-type(n+3)
  border-top: 1px solid $border-color
.item:not(:nth-of-type(2n+1))
  border-left: 1px solid $border-color
@media (min-width: $lg)
  .sliderContainer
    position: relative
    // calc the remaining height of menu if we subtract header and 3 rows
    height: calc(100vh - #{$header-height} - (#{$menu-item-height} * 3))
  .menuContent
    flex-direction: row
  // Carousel
  .consolesCarousel
    display: block
  .center
    display: flex
    align-items: center
  .right.side .groupList
    margin-top: unset
</style>
