var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "credits text-xs-center" },
    [
      _c("ToTop"),
      _c("div", { staticClass: "creditsArea" }, [
        _c(
          "div",
          [
            _vm._v("Developed with\n      "),
            _c("BaseIcon", { attrs: { icon: "heart" } }),
            _vm._v(" and tons of\n      "),
            _c(
              "div",
              { staticClass: "coffee" },
              [
                _c(
                  "span",
                  { staticClass: "smoke" },
                  [_c("BaseIcon", { attrs: { icon: "fire" } })],
                  1
                ),
                _c("BaseIcon", { attrs: { icon: "coffee" } })
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }