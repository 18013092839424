<script>
import theNavbar from '@partials/TheNavbar'
import theFooter from '@partials/TheFooter'
import appConfig from '@src/app.config'

export default {
  name: 'App',
  meta: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      return title ? `${title} | ${appConfig.title}` : appConfig.title
    },
    htmlAttrs: {
      lang: 'en',
      amp: true
    },
  },
  components: {
    theNavbar,
    theFooter,
  },
  data() {
    return {
      isOverflowed: true,
    }
  },
  created() {
    this.$store.dispatch('loadConsoleCats')
    this.$store.dispatch('loadConsoles')
  },
  methods: {
    toggleOverflow() {
      this.isOverflowed = !this.isOverflowed
    },
  },
}
</script>

<template>
  <div
    id="app"
    :class="{overflowed : $store.state.isNavOpen}">
    <div :class="['wrapper']">
      <theNavbar/>
      <transition name="router">
        <router-view/>
      </transition>
      <theFooter/>
    </div>
  </div>
</template>

<style lang="sass">
@import '~@assets/style/style.sass'

#app
.accentGreen
  color: $c-acc-green
.accentBlue
  color: $c-acc-blue
.accentPink
  color: $c-acc-pink
.wrapper
  position: relative
  padding-top: 80px

.page
  margin-bottom: $section-margin-bottom

// Router Transition
.router-enter-active
  opacity: 0
  animation: coming 1s
  animation-delay: 1s

.router-leave-active
  animation: going 1s

@keyframes going
  from
    opacity: 1
  to
    opacity: 0
@keyframes coming
  from
    opacity: 0
  to
    opacity: 1
</style>
