var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "filter filterGenerations",
      class: { active: this.$store.state.generation },
      on: {
        click: function($event) {
          return _vm.$store.dispatch("closeFilters")
        }
      }
    },
    [
      _c("h3", { staticClass: "title" }, [_vm._v("Generation")]),
      _c(
        "div",
        { staticClass: "content" },
        _vm._l(_vm.generations, function(generation) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value:
                    generation.name +
                    " Generation<br>" +
                    generation.startYear +
                    " - " +
                    generation.endYear,
                  expression:
                    "`${generation.name} Generation<br>${generation.startYear} - ${generation.endYear}`"
                }
              ],
              key: generation.startYear,
              class: [
                "item",
                { active: generation.name == _vm.$store.state.generation }
              ],
              style: "width: " + generation.percent + "%",
              on: {
                click: function($event) {
                  return _vm.selectItem(generation.name)
                }
              }
            },
            [_c("div", { staticClass: "itemInner" })]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }