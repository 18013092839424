var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["page", "singleConsolePage"] },
    [
      _c("LayWithSidebar", { staticClass: "topContent" }, [
        _c("div", { attrs: { slot: "sidebar" }, slot: "sidebar" }, [
          _c(
            "div",
            { staticClass: "sidebar hidden-xs hidden-md-off" },
            [
              _c("ItemsOverflowedList", {
                attrs: {
                  items: _vm.consoles,
                  "current-item": _vm.consola && _vm.consola.name,
                  height: 620
                }
              })
            ],
            1
          )
        ]),
        _vm.consola
          ? _c("div", { attrs: { slot: "content" }, slot: "content" }, [
              _c("div", { staticClass: "mainContent" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "jar-xs-100" }, [
                    _c("h1", { staticClass: "title" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.consola.name) +
                          "\n              "
                      ),
                      _vm.consola.nickname
                        ? _c("span", { staticClass: "nickname" }, [
                            _vm._v(":: " + _vm._s(_vm.consola.nickname))
                          ])
                        : _vm._e()
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "details jar-md-50" }, [
                    _c(
                      "div",
                      { staticClass: "characteristics" },
                      [
                        _c(
                          "div",
                          { staticClass: "category characteristic" },
                          _vm._l(_vm.categories, function(category, index) {
                            return _c("div", { key: index }, [
                              category.id === _vm.consola.cat.id
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: category.name,
                                          expression: "category.name"
                                        }
                                      ]
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "consoles",
                                              params: {
                                                cat:
                                                  _vm.consola.cat.alias ||
                                                  "none"
                                              }
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "categoryIcon",
                                            attrs: {
                                              src: category.photo.url,
                                              alt: category.name
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          }),
                          0
                        ),
                        _c("ConsolePageMedia", {
                          attrs: { media: _vm.consola.media }
                        }),
                        _vm.consola.bits
                          ? _c("div", { staticClass: "bits" }, [
                              _vm._v(_vm._s(_vm.consola.bits))
                            ])
                          : _vm._e(),
                        _vm.consola.educational
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: "Educational",
                                    expression: "'Educational'"
                                  }
                                ],
                                staticClass: "educational"
                              },
                              [
                                _c("BaseIcon", {
                                  staticClass: "mediaIcon educationalIcon",
                                  attrs: { icon: "microscope" }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "manufacturer heading" },
                      [
                        _vm._v("\n              Manufacturer:\n              "),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "search",
                                params: {
                                  input: _vm.consola.brand[0].alias || "none"
                                }
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "bold" }, [
                              _vm._v(_vm._s(_vm.consola.brand[0].name))
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "predecessor heading" }, [
                      _vm._v("\n              Predecessor:\n              "),
                      _c("span", { staticClass: "bold" }, [
                        _vm.consola.previous
                          ? _c(
                              "span",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "link",
                                    attrs: {
                                      to: {
                                        name: "console",
                                        params: {
                                          alias: _vm.consola.previous.alias
                                        }
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.consola.previous.name))]
                                )
                              ],
                              1
                            )
                          : _c("span", [_vm._v("-")])
                      ])
                    ]),
                    _c("div", { staticClass: "successor heading" }, [
                      _vm._v("\n              Successor:\n              "),
                      _c("span", { staticClass: "bold" }, [
                        _vm.consola.next
                          ? _c(
                              "span",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "link",
                                    attrs: {
                                      to: {
                                        name: "console",
                                        params: {
                                          alias: _vm.consola.next.alias
                                        }
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.consola.next.name))]
                                )
                              ],
                              1
                            )
                          : _c("span", [_vm._v("-")])
                      ])
                    ]),
                    _vm.consola.replica
                      ? _c("div", { staticClass: "replica heading" }, [
                          _vm._v("\n              Replica of:\n              "),
                          _c("span", { staticClass: "bold" }, [
                            _vm._v(_vm._s(_vm.consola.replica.name || "-"))
                          ])
                        ])
                      : _vm._e(),
                    _vm.consola.releaseDate.first
                      ? _c("div", { staticClass: "date heading" }, [
                          _vm._v(
                            "\n              Release Date:\n              "
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.validDates(
                                    _vm.consola.releaseDate
                                  ),
                                  expression: "validDates(consola.releaseDate)"
                                }
                              ],
                              staticClass: "bold"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.convertDate(_vm.consola.releaseDate.first)
                                )
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm.consola.discontinuedDate.last
                      ? _c("div", { staticClass: "date heading" }, [
                          _vm._v(
                            "\n              Discontinued Date:\n              "
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.validDates(
                                    _vm.consola.disconinuedDate
                                  ),
                                  expression:
                                    "validDates(consola.disconinuedDate)"
                                }
                              ],
                              staticClass: "bold"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.convertDate(
                                    _vm.consola.discontinuedDate.last
                                  )
                                )
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "sizes heading" }, [
                      _vm._v(
                        "\n              Additional Sizes:\n              "
                      ),
                      _c("span", { staticClass: "bold" }, [
                        _vm._v(_vm._s(_vm.consola.sizes || "-"))
                      ])
                    ]),
                    _c("div", { staticClass: "palette heading" }, [
                      _vm._v(
                        "\n              Display Palette:\n              "
                      ),
                      _c("span", { staticClass: "bold" }, [
                        _vm._v(_vm._s(_vm.consola.palette || "-"))
                      ])
                    ]),
                    _c("div", { staticClass: "price heading" }, [
                      _vm._v("\n              Initial Price:\n              "),
                      _c("span", { staticClass: "bold" }, [
                        _vm._v(_vm._s(_vm.pricy(_vm.consola.price) || "-"))
                      ])
                    ]),
                    _c("br"),
                    _vm.consola.pdf
                      ? _c("div", { staticClass: "pdf" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: _vm.consola.pdf, target: "_blank" }
                            },
                            [
                              _c("BaseIcon", {
                                staticClass: "pdfIcon",
                                attrs: { icon: "file-pdf" }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    { staticClass: "gallery jar-md-50" },
                    [
                      _c(
                        "Carousel",
                        {
                          attrs: {
                            "per-page": 1,
                            "pagination-size": 8,
                            "pagination-padding": 5,
                            "pagination-enabled": true,
                            "pagination-active-color": "#51A15C"
                          }
                        },
                        [
                          _c(
                            "Slide",
                            {
                              staticClass: "consoleSlide",
                              attrs: {
                                title:
                                  _vm.consola.photo.alt && _vm.consola.photo.alt
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "sliderImg" },
                                [
                                  _c("BaseImage", {
                                    attrs: {
                                      title:
                                        _vm.consola.photo.alt &&
                                        _vm.consola.photo.alt,
                                      source: _vm.consola.photo.thumb
                                        ? _vm.consola.photo.thumb
                                        : "",
                                      alt:
                                        _vm.consola.photo.alt &&
                                        _vm.consola.photo.alt,
                                      "custom-text": _vm.consola.unreleased
                                        ? "UNRELEASED"
                                        : ""
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._l(_vm.consola.gallery, function(photo, index) {
                            return index < 8
                              ? _c(
                                  "Slide",
                                  {
                                    key: index,
                                    staticClass: "consoleSlide",
                                    attrs: { title: photo.alt }
                                  },
                                  [
                                    _c("div", { staticClass: "sliderImg" }, [
                                      _c("img", {
                                        attrs: {
                                          title: photo.alt,
                                          src: photo.thumb,
                                          alt: photo.alt
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm.consola.sales && _vm.consola.cat.bestSales
                ? _c(
                    "div",
                    { key: _vm.alias, staticClass: "pies text-xs-center" },
                    [
                      _c(
                        "div",
                        { staticClass: "salesPie" },
                        [
                          _c("Progress", {
                            attrs: {
                              currentVal: _vm.consola.sales,
                              maxVal: _vm.consola.cat.bestSales,
                              size: 400,
                              title: "Sales"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _vm.consola
        ? _c("LayOneColumn", [
            _c("div", { staticClass: "row bottomContent" }, [
              _vm.consola.description
                ? _c("div", { staticClass: "content jar-md-80" }, [
                    _c("div", {
                      staticClass: "description",
                      domProps: { innerHTML: _vm._s(_vm.consola.description) }
                    })
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  class: [
                    "generationSidebar",
                    _vm.consola.description ? "jar-md-20" : "jar-md-100",
                    "hidden-xs",
                    "hidden-md-off"
                  ]
                },
                [
                  _c("SingleConsoleGenerations", {
                    attrs: {
                      "current-generation": _vm.consola.generation,
                      horizontal: _vm.consola.description ? false : true
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _vm.consola.relatedAccessories
                ? _c(
                    "div",
                    { staticClass: "supportedAccessories jar-xs-100" },
                    [
                      _c("BaseSectionTitle", {
                        attrs: {
                          icon: "○",
                          "icon-accent": "pink",
                          title: "Supported Accessories"
                        }
                      }),
                      _c(
                        "Carousel",
                        {
                          staticClass: "relatedCarousel",
                          attrs: {
                            "per-page-custom": [[0, 1], [412, 2], [992, 3]],
                            "pagination-size": 8,
                            "pagination-padding": 5,
                            "pagination-enabled": true,
                            "pagination-active-color": "#51A15C"
                          }
                        },
                        _vm._l(_vm.consola.relatedAccessories, function(
                          item,
                          index
                        ) {
                          return _c(
                            "Slide",
                            { key: index, staticClass: "relatedSlide" },
                            [_c("ListItem", { attrs: { item: item } })],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }