<template>
  <div class="aboutIntro">
    <router-link :to="{ path: '/about' }">
      <p>{{ aboutIntro }} ...</p>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'FooterAbout',
  computed: {
    aboutIntro() {
      return this.$store.state.about.short
    },
  },
  created() {
    this.$store.dispatch('loadAbout')
  },
}
</script>

<style scoped lang="sass">
@import '~@assets/style/designs'

.aboutIntro
  margin-top: 45px
  margin-bottom: 50px
@media (min-width: $md)
  .aboutIntro
    margin-bottom: 0
</style>
