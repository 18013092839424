import Vue from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faCoffee,
  faCube,
  faGamepad,
  faSearch,
  faCaretDown,
  faLongArrowAltUp,
  faHeart,
  faCopyright,
  faFire,
  faChevronDown,
  faFilePdf,
  faCompactDisc,
  faSdCard,
  faCloud,
  faMicrochip,
  faTape,
  faMicroscope,
  faImage,
  faFlag,
  faLink
} from '@fortawesome/free-solid-svg-icons'

import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube,
  faUsb
} from '@fortawesome/free-brands-svg-icons'

export let fontAwesomeIcons = [
  faCoffee,
  faCube,
  faGamepad,
  faSearch,
  faCaretDown,
  faLongArrowAltUp,
  faHeart,
  faCopyright,
  faFire,
  faChevronDown,
  faFilePdf,
  faCompactDisc,
  faSdCard,
  faCloud,
  faMicrochip,
  faTape,
  faMicroscope,
  faImage,
  faFlag,
  faLink,
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube,
  faUsb
]

Vue.component('font-awesome-icon', FontAwesomeIcon)
