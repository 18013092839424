var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["menuBtn", { active: _vm.$store.state.isNavOpen }],
      on: {
        click: function($event) {
          return _vm.$emit("menuBtnClicked")
        }
      }
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "menuBtnContainer" }, [
      _c("div", { staticClass: "buttonGroup" }, [
        _c("span", { staticClass: "button" }),
        _c("span", { staticClass: "button" })
      ]),
      _c("div", { staticClass: "buttonGroup" }, [
        _c("span", { staticClass: "button" }),
        _c("span", { staticClass: "button" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }