var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items.length > 0
    ? _c("div", [
        _c("div", { staticClass: "filter" }, [
          _c("h3", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.closeList,
                  expression: "closeList"
                }
              ],
              staticClass: "filterSelect text-xs-center",
              on: { click: _vm.toggleList }
            },
            [
              _c(
                "div",
                { staticClass: "selectedHeadingArea" },
                [
                  _c("h5", { staticClass: "selectedTitle" }, [
                    _vm._v(_vm._s(_vm._f("dashspace")(_vm.selectedItem)))
                  ]),
                  _c("BaseIcon", {
                    staticClass: "caret",
                    class: { active: _vm.listActive },
                    attrs: { icon: "caret-down" }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "selectList",
                  class: { active: _vm.listActive },
                  on: {
                    click: function($event) {
                      return _vm.$store.dispatch("closeFilters")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "option",
                      class: { selected: _vm.selectedItem === "all" },
                      on: {
                        click: function($event) {
                          return _vm.selectItem("")
                        }
                      }
                    },
                    [_vm._v("All " + _vm._s(_vm.title))]
                  ),
                  _vm._l(_vm.items, function(item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "option",
                        class: {
                          selected:
                            item.name.toLowerCase().replace(" ", "-") ===
                            _vm.selectedItem
                        },
                        on: {
                          click: function($event) {
                            return _vm.selectItem(item.name)
                          }
                        }
                      },
                      [_vm._v(_vm._s(item.name))]
                    )
                  })
                ],
                2
              )
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }