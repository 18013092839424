import axios from 'axios';
import handleError from './handleError';

const httpClient = axios.create({
  // baseURL: `http://api.pearson.com/v2/dictionaries`,
  withCredentials: false,
  headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
  }
})



// HTTP GET Request - Returns Resolved or Rejected Promise
export const get = apiLink => {
  return new Promise((resolve, reject) => {
    httpClient.get(apiLink)
      .then(response => resolve(response))
      .catch(error =>
        reject(handleError(error))
      )
  });
};
