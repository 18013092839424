export default function(num) {
  // Twelve Zeroes for Trillions
  return num >= 1.0e12
    ? (num / 1.0e12).toFixed(1) + 'T'
    : // Nine Zeroes for Billions
    num >= 1.0e9
    ? (num / 1.0e9).toFixed(1) + 'B'
    : // Six Zeroes for Millions
    num >= 1.0e6
    ? (num / 1.0e6).toFixed(1) + 'M'
    : // Three Zeroes for Thousands
    num >= 1.0e3
    ? (num / 1.0e3).toFixed(1) + 'K'
    : num
}
