var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "consoleItem text-xs-center" },
      [
        _vm.item.releaseΥear
          ? _c(
              "BaseLabel",
              {
                staticClass: "yearLabel",
                attrs: {
                  float: "",
                  size: "small",
                  position: "top right inset",
                  shape: "circle",
                  color: "gradientPink"
                }
              },
              [_vm._v(_vm._s(_vm.item.releaseΥear))]
            )
          : _vm._e(),
        _c(
          "router-link",
          {
            attrs: {
              to: { name: _vm.item.type, params: { alias: _vm.item.alias } }
            }
          },
          [
            _c(
              "div",
              { staticClass: "consolePhoto" },
              [
                _c("BaseImage", {
                  staticClass: "itemImage",
                  attrs: {
                    source: _vm.item.photo.thumb,
                    alt: _vm.item.photo.alt,
                    "custom-text": _vm.item.unreleased ? "UNRELEASED" : ""
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "consoleTitle" }, [
              _c("h3", [
                _vm._v("\n          " + _vm._s(_vm.item.name) + "\n          "),
                _c("br"),
                _c("span", [_vm._v("by " + _vm._s(_vm.item.brand))])
              ])
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }