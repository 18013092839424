<script>
export default {
  name: 'NotFound',
  props: {
    title: {
      type: String,
      default: 'Not Found'
    },
    message: {
      type: String,
      default: 'Please try again later'
    }
  }
}
</script>

<template>
  <div class="notFound">
    <h1>{{ title }}</h1>
    <BaseIcon
      :icon="'flag'"
      class="icon"/>
    <h3 class="message">{{ message }}</h3>
  </div>
</template>

<style lang="sass" scoped>
@import '~@assets/style/designs'

.notFound
  text-align: center
.icon
  margin-bottom: $section-spacing
  font-size: 80px
.message
  margin-bottom: $section-spacing
</style>
