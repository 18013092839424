<script>
import LayOneColumn from '@layouts/LayOneColumn'
import CategoryPageContent from '@partials/CategoryPageContent'
import { get } from '@services/apiUtils'

export default {
  name: 'ConsolesCategoryPage',
  meta() {
    return {
      title: this.title || '',
      meta: [
        { name: 'description', content: `Everything about gaming, gaming consoles and accessories. Find the ${this.title || 'items'} you are looking for.` },
      ],
    }
  },
  components: {
    LayOneColumn,
    CategoryPageContent,
  },
  data() {
    return {
      apiItems: [],
      headerTitle: ''
    }
  },
  computed: {
    type() {
      return this.$route.params.type
    },
    characteristic() {
      return this.$route.params.characteristic || ''
    },
    items() {
      return this.apiItems
    },
    title() {
      return this.headerTitle || ''
    }
  },
  watch: {
    '$route.params': function() {
      this.get()
    }
  },
  created() {
    this.get()
  },
  methods: {
    get() {
      get(`${process.env.VUE_APP_API_BASE_URL}/${this.type}/${this.characteristic && this.characteristic + '/'}`).then(body => {
        this.headerTitle = body.headers['x-category-title']
        this.apiItems = body.data
      })
    }
  }
}
</script>

<template>
  <div class="page">
    <LayOneColumn>
      <h1>{{ title }}</h1>
      <CategoryPageContent :items="items"/>
    </LayOneColumn>
  </div>
</template>

<style scoped lang="sass">
@import '~@assets/style/designs'

</style>
