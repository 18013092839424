<template>
  <div class="layerWithSidebar">
    <div class="container">
      <div class="row">
        <div :class="[{'jar-md-push-70' : !sidebarLeft},'jar-md-30', 'sticky', 'sidebarOuter']">
          <slot name="sidebar"/>
        </div>
        <div :class="[{'jar-md-pull-30' : !sidebarLeft}, 'jar-md-70']">
          <div class="content">
            <slot name="content"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayWithSidebar',
  props: {
    sidebarLeft: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
}
</script>

<style scoped lang="sass">
@import '~@assets/style/designs'

.layerWithSidebar
  margin-top: $section-margin-top
.sidebarOuter
  z-index: 10
  margin-bottom: $base-spacing

@media (min-width: $md)
  .sticky
    position: sticky
    top: $header-height + $section-margin-top
    margin-bottom: $base-spacing
</style>
