<template>
  <div>
    <div class="consoleItem text-xs-center">
      <BaseLabel
        v-if="item.releaseΥear"
        class="yearLabel"
        float
        size="small"
        position="top right inset"
        shape="circle"
        color="gradientPink">{{ item.releaseΥear }}</BaseLabel>
      <router-link :to="{name: item.type, params: {alias: item.alias}}">
        <div class="consolePhoto">
          <BaseImage
            :source="item.photo.thumb"
            :alt="item.photo.alt"
            :custom-text="item.unreleased ? 'UNRELEASED' : ''"
            class="itemImage"/>
        </div>
        <div class="consoleTitle">
          <h3>
            {{ item.name }}
            <br>
            <span>by {{ item.brand }}</span>
          </h3>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListItem',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="sass">
@import '~@assets/style/designs'

$console-desk-size: 250px

.yearLabel
	opacity: 0
	transition: opacity .3s ease-in-out
.consoleItem
	position: relative
	height: $console-desk-size
	padding: $base-spacing
	margin-bottom: $base-spacing
	overflow: hidden
	background: $bg-color
	box-shadow: $base-shadow
	transition: background $prim-trans-time $prim-trans-easing
	&:hover .yearLabel
		opacity: 1
	&::after
		position: absolute
		top: 0
		left: 0
		z-index: -1
		width: 100%
		height: 100%
		content: ""
		background: $green-blue-purple-gradient
	&:hover
		background: transparentize($bg-color, 1)

	.consoleTitle
		position: absolute
		right: 0
		bottom: $base-spacing
		left: 0
		height: ($console-desk-size - ($base-spacing * 2)) / 4
	h3
		margin: 0 auto
		font-size: 0.8rem
		font-weight: normal
		text-transform: uppercase
		span
			color: $text-muted
	hr
		margin: 5px $base-spacing
		border-color: transparentize($c-acc-green, 0.9)
.itemImage
	height: ($console-desk-size - ($base-spacing * 2)) * 0.6666
</style>
