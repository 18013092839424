var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "slideshow",
      style: "background-image: url(" + _vm.slideshowBg + ");"
    },
    [
      _c(
        "div",
        { staticClass: "slideshowInner" },
        [
          _c(
            "carousel",
            {
              staticClass: "homepageCarousel",
              attrs: {
                "navigate-to": 1,
                "per-page": 1,
                autoplay: true,
                "autoplay-timeout": 7000,
                "autoplay-hover-pause": true,
                "pagination-size": 8,
                "pagination-padding": 5,
                "pagination-enabled": true,
                "pagination-active-color": "#51A15C",
                "pagination-position": "bottom-overlay"
              }
            },
            _vm._l(_vm.slides, function(slide) {
              return _c("slide", { key: slide.index, staticClass: "slide" }, [
                _c("div", { staticClass: "container" }, [
                  _c("div", { staticClass: "row v-align-sm" }, [
                    _c(
                      "div",
                      { staticClass: "jar-sm-60 jar-sm-push-40 slide-content" },
                      [
                        _c("h2", { staticClass: "slideTitle" }, [
                          _vm._v(_vm._s(slide.name))
                        ]),
                        _c("div", {
                          staticClass: "description",
                          domProps: { innerHTML: _vm._s(slide.descr) }
                        }),
                        _c(
                          "div",
                          { staticClass: "text-md-right" },
                          [
                            _c("BaseButton", {
                              staticClass: "accentGreenBlue",
                              attrs: { text: "READ MORE", link: slide.link }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "jar-sm-40 jar-sm-pull-60 text-xs-center"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "slideImg v-align-sm" },
                          [
                            _c("BaseImage", {
                              attrs: {
                                source: slide.photo.url,
                                alt: slide.photo.alt
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ])
              ])
            }),
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }