var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { overflowed: _vm.$store.state.isNavOpen }, attrs: { id: "app" } },
    [
      _c(
        "div",
        { class: ["wrapper"] },
        [
          _c("theNavbar"),
          _c(
            "transition",
            { attrs: { name: "router" } },
            [_c("router-view")],
            1
          ),
          _c("theFooter")
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }