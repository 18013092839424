<script>
export default {
  name: 'BaseButton',
  props: {
    text: {
      type: String,
      default: 'Button',
    },
    link: {
      type: String,
      default: null,
    },
  },
}
</script>

<template>
  <div class="mainButton">
    <a
      v-if="link"
      :href="link"
      class="buttonText">{{ text }}</a>
    <span
      v-else
      class="buttonText">{{ text }}</span>
  </div>
</template>

<style scoped lang="sass">
@import "~@assets/style/designs"

.mainButton
	position: relative
	display: inline-block
	font-family: $font-family-heading
	cursor: pointer
	border: 2px solid $c-white
	&::before
		position: absolute
		top: 0
		left: 0
		z-index: -1
		width: 100%
		height: 100%
		content: ''
		opacity: 0
		transition: opacity $prim-trans-time $prim-trans-easing
	&:hover::before
		opacity: 1
	&.accentGreenBlue
		border-image: $green-gradient
		border-image-slice: 1
		&::before
			background: $green-gradient
.buttonText
	z-index: 10
	display: block
	padding: 0.7rem 1.5rem
	font-weight: bold
	color: $c-white

	// &.accentTwo
	// 	-moz-border-image: -moz-linear-gradient(right bottom, $c-acc-red 0%, $base-color-light 100%)
	// 	-webkit-border-image: -webkit-linear-gradient(right bottom, $c-acc-red 0%, $base-color-light 100%)
	// 	border-image: linear-gradient(to right bottom, $c-acc-red 0%, $base-color-light 100%)
	// 	border-image-slice: 1
	// 	&::before
	// 		background: linear-gradient(160deg, $c-acc-red, $base-color-light)

	// &.accentThree
	// 	-moz-border-image: -moz-linear-gradient(right bottom, $c-acc-blue 0%, $base-color-light 100%)
	// 	-webkit-border-image: -webkit-linear-gradient(right bottom, $c-acc-blue 0%, $base-color-light 100%)
	// 	border-image: linear-gradient(to right bottom, $c-acc-blue 0%, $base-color-light 100%)
	// 	border-image-slice: 1
	// 	&::before
	// 		background: linear-gradient(160deg, $c-acc-blue, $base-color-light)

	// &.accentFour
	// 	-moz-border-image: -moz-linear-gradient(right bottom, $c-acc-pink-dark 0%, $c-acc-blue-dark 100%)
	// 	-webkit-border-image: -webkit-linear-gradient(right bottom, $c-acc-pink-dark 0%, $c-acc-blue-dark 100%)
	// 	border-image: linear-gradient(to right bottom, $c-acc-pink-dark 0%, $c-acc-blue-dark 100%)
	// 	border-image-slice: 1
	// 	&::before
	// 		background: linear-gradient(160deg, $c-acc-pink-dark, $c-acc-blue-dark)
</style>
