<script>
import LayOneColumn from '@layouts/LayOneColumn'
import { Carousel, Slide } from 'vue-carousel'
import ListItem from '@components/ListItem'

export default {
  name: 'SingleAccessoryPage',
  meta() {
    return {
      title: `${this.title} Page` || 'Console Accessory',
      meta: [
        {
          name: 'description',
          content: this.accessory.description || `Get the best info regarding ${this.title || 'shown accessory'}`,
        },
      ],
    }
  },
  components: {
    LayOneColumn,
    Carousel,
    Slide,
    ListItem,
  },
  computed: {
    accessory() {
      return this.$store.state.singleAccessory
    },
    title() {
      return this.accessory && this.accessory.brand && `${this.accessory.brand.name} ${this.accessory.name}`
    }
  },
  created() {
    this.$store.dispatch('loadSingleAccessory', this.$route.params.alias)
  },
  methods: {
    convertDate(oldDate) {
      let date = new Date(oldDate)
      date = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
      return date
    },
  },
}
</script>

<template>
  <div :class="['page', 'singleAccessoryPage']">
    <LayOneColumn>
      <div>
        <div
          v-if="accessory"
          class="mainContent topContent">
          <!-- MAIN CONTENT -->
          <div class="row">
            <div class="details jar-md-50">
              <!-- DETAILS -->
              <h1 class="title">{{ accessory.name }}</h1>
              <div class="manufacturer heading">
                <router-link
                  :to="{name:'search', params:{input: accessory.brand.name || 'none'}}"
                >Manufacturer:
                  <span class="head">{{ accessory.brand.name }}</span>
                </router-link>
              </div>
              <div class="category">
                <router-link
                  :to="{name:'accessories', params:{cat: accessory.cat.alias || 'none'}}"
                >
                  <BaseLabel
                    color="green"
                    size="medium"
                    class="label"
                    spacing="12px 0">{{ accessory.cat.name }}</BaseLabel>
                </router-link>
              </div>
            </div>
            <!-- /.DETAILS -->
            <div class="gallery jar-md-50">
              <Carousel
                :per-page="1"
                :pagination-size="8"
                :pagination-padding="5"
                :pagination-enabled="true"
                pagination-active-color="#51A15C"
              >
                <Slide
                  :title="accessory.photo.alt"
                  class="consoleSlide">
                  <div class="sliderImg">
                    <BaseImage
                      :title="accessory.photo.alt"
                      :source="accessory.photo.thumb"
                      :alt="accessory.photo.alt"
                    />
                  </div>
                </Slide>
                <Slide
                  v-for="(photo, index) in accessory.gallery"
                  v-if="index < 8"
                  :key="index"
                  :title="photo.alt"
                  class="consoleSlide"
                >
                  <div class="sliderImg">
                    <img
                      :title="photo.alt"
                      :src="photo.thumb"
                      :alt="photo.alt">
                  </div>
                </Slide>
              </Carousel>
            </div>
          </div>
        </div>
        <!-- /.MAIN CONTENT -->
      </div>

      <div class="row bottomContent">
        <div class="content jar-xs-100">
          <div
            v-if="accessory.description"
            class="description"
            v-html="accessory.description"/>
        </div>
        <br>
        <br>
        <div class="supportedConsoles jar-xs-100">
          <BaseSectionTitle
            :icon="'○'"
            :icon-accent="'pink'"
            :title="'Supported Consoles'"/>
          <Carousel
            :per-page-custom="[[0, 1], [412, 2], [768, 3], [992, 4]]"
            :pagination-size="8"
            :pagination-padding="5"
            :pagination-enabled="true"
            pagination-active-color="#51A15C"
            class="relatedCarousel"
          >
            <Slide
              v-for="(item, index) in accessory.relatedConsoles"
              :key="index"
              class="relatedSlide">
              <ListItem :item="item"/>
            </Slide>
          </Carousel>
        </div>
      </div>
    </LayOneColumn>
  </div>
</template>

<style lang="sass" scoped>
@import '~@assets/style/designs'

.topContent
  margin: $section-margin-bottom / 2 0

.title
  margin: 0
  font-size: 33px
  font-weight: bold

.mainContent
  position: relative
  padding: $base-spacing * 2
  overflow: hidden
  background: $ebony-gradient
  border-radius: 3px
  box-shadow: $base-shadow
  &::after
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 1px
    content: ''
    background: $rainbow-gradient

.relatedCarousel
  margin: 0 $base-spacing / -2
.relatedSlide
  padding: 0 $base-spacing / 2

.sliderImg
  max-width: 100%
  max-height: 100%
  text-align: center
  img
    max-width: 100%
.heading
  @extend %heading

  .head
    font-weight: bold
.manufacturer
  .head
    text-transform: uppercase
.description
  color: $text-faded

</style>
