import Vue from 'vue'
import router from '@router'
import App from './App.vue'
import BaseButton from '@components/BaseButton.vue'
import BaseIcon from '@components/BaseIcon.vue'
import BaseImage from '@components/BaseImage.vue'
import BaseLabel from '@components/BaseLabel.vue'
import BaseSectionTitle from '@components/BaseSectionTitle.vue'
import { store } from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fontAwesomeIcons } from '@src/fa'
import ClickOutside from '@directives/ClickOutside'
import LazyLoad from '@directives/LazyLoad'
import CxltToastr from 'cxlt-vue2-toastr'
import 'cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css'
import VTooltip from 'v-tooltip'
import counting from '@filters/counting'
import excerpt from '@filters/Excerpt'
import toCamelCase from '@filters/ToCamelCase'
import removeDash from '@filters/RemoveDash'

// Notifications Plugin
Vue.use(CxltToastr)
// Meta Plugin

// Tooltip Plugin
Vue.use(VTooltip)

// VUE Directives
Vue.directive('click-outside', ClickOutside)
Vue.directive('lazyload', LazyLoad)

// VUE Filters
Vue.filter('dashspace', function(value) {
  return value.replace('-', ' ')
})

Vue.filter('removeDash', removeDash)
Vue.filter('counting', counting)
Vue.filter('excerpt', excerpt)
Vue.filter('toCamelCase', toCamelCase)

// Font Awesome Library
library.add(fontAwesomeIcons)

// Global Components
Vue.component(BaseButton.name, BaseButton)
Vue.component(BaseIcon.name, BaseIcon)
Vue.component(BaseImage.name, BaseImage)
Vue.component(BaseLabel.name, BaseLabel)
Vue.component(BaseSectionTitle.name, BaseSectionTitle)

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
