var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filterItem filterCategories" }, [
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.cats, function(category) {
        return _c(
          "div",
          { key: category.id, staticClass: "jar-xs-33 text-xs-center" },
          [
            _c(
              "div",
              {
                staticClass: "categoryItem",
                on: {
                  click: function($event) {
                    return _vm.updateCatRoute(category.alias)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: category.name,
                        expression: "category.name"
                      }
                    ],
                    staticClass: "categoryImage"
                  },
                  [
                    _c("img", {
                      class: [
                        "image",
                        { active: category.alias == _vm.$route.params.cat }
                      ],
                      attrs: {
                        src: category.photo.url,
                        alt: category.name + " Category"
                      }
                    })
                  ]
                )
              ]
            )
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }