var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "categoryContent" }, [
    _c("div", { staticClass: "row halfSpacing" }, [
      _vm.items.length > 0
        ? _c(
            "div",
            [
              _c(
                "transition-group",
                { attrs: { name: "fade", tag: "div" } },
                _vm._l(_vm.items, function(item) {
                  return _c("ListItem", {
                    key: item.id,
                    staticClass: "jar-sm-50 jar-md-33 jar-xl-20 item",
                    attrs: { item: item }
                  })
                }),
                1
              )
            ],
            1
          )
        : _c("div", [
            _vm.isFilter
              ? _c("div", [
                  _c("h1", { staticClass: "text-xs-center" }, [
                    _vm._v("Try another filter criteria or\n          "),
                    _c(
                      "span",
                      {
                        staticClass: "clearText",
                        on: {
                          click: function($event) {
                            return _vm.clearFilters()
                          }
                        }
                      },
                      [_vm._v("clear")]
                    ),
                    _vm._v(" filter\n        ")
                  ])
                ])
              : _vm._e()
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }