import Vue from 'vue'
import Vuex from 'vuex'
import { get } from '@services/apiUtils'
import {
  getByKeyword,
  getByCategory,
  getByGeneration,
  getByBrand,
  getByMedium,
} from '@utils/filterItems'

Vue.use(Vuex)

const apiUrl = process.env.VUE_APP_API_BASE_URL

export const store = new Vuex.Store({
  // A :: STATE
  state: {
    about: '',
    singleAccessory: '',
    consoleCats: [],
    accessoriesCats: [],
    consoles: [],
    accessories: [],
    generations: [],
    brands: [],
    gameMedia: [],
    slideshows: [],
    slideshowBg: '',
    socials: [],
    faqs: {},

    keyword: '',
    category: '',
    generation: '',
    brand: '',
    medium: '',

    accessoriesCategory: '',
    accessoriesBrand: '',

    isNavOpen: false,
    isFilterOpen: false,
  },

  // B :: MUTATIONS
  mutations: {
    updateSingleAccessory(state, item) {
      let singleAccessory = item
      state.singleAccessory = singleAccessory
    },
    updateConsoleCats(state, cats) {
      state.consoleCats = cats
    },
    updateAccessoriesCats(state, cats) {
      state.accessoriesCats = cats
    },
    updateConsoles(state, consoles) {
      state.consoles = consoles
    },
    updateAccessories(state, accessories) {
      state.accessories = accessories
    },

    updateGenerations(state, generations) {
      const today = new Date()
      const currentYear = today.getFullYear().toString()
      generations.forEach(item => {
        item.endYear === currentYear && (item.endYear = 'Today')
      })
      state.generations = generations
    },
    updateBrands(state, brands) {
      state.brands = brands
    },
    updateGameMedia(state, gameMedia) {
      state.gameMedia = gameMedia
    },

    updateSlideshows(state, slideshows) {
      state.slideshows = slideshows
    },
    updateSlideshowBg(state, slideshowBg) {
      state.slideshowBg = slideshowBg
    },

    updateSocials(state, socials) {
      state.socials = socials
    },
    updateAbout(state, about) {
      state.about = about
    },
    updateFaqs(state, faqs) {
      state.faqs = faqs
    },

    // Update Console Filters
    updateKeyword(state, keyword) {
      state.keyword = keyword
    },
    updateCategory(state, category) {
      state.category = category
    },
    updateGeneration(state, generation) {
      state.generation = generation
    },
    updateBrand(state, brand) {
      state.brand = brand
    },
    updateMedium(state, medium) {
      state.medium = medium
    },

    // Update Accessories Filters
    updateAccessoriesCategory(state, category) {
      state.accessoriesCategory = category
    },
    updateAccessoriesBrand(state, brand) {
      state.accessoriesBrand = brand
    },


    // Navigation Mutations
    toggleNav(state) {
      state.isNavOpen = !state.isNavOpen
    },
    closeNav(state) {
      state.isNavOpen = false
    },

    // Filters Mutations
    toggleFilters(state) {
      state.isFilterOpen = !state.isFilterOpen
    },
    closeFilters(state) {
      state.isFilterOpen = false
    },
    clearFilters(state) {
      state.generation = ''
      state.brand = ''
      state.medium = ''
      state.accessoriesBrand = ''
    },
  },

  // C :: ACTIONS
  actions: {
    // Load Single Accessory
    loadSingleAccessory({ commit }, alias) {
      get(`${apiUrl}/accessory/${alias}/`).then(body => {
        commit('updateSingleAccessory', body.data)
      })
    },
    // Load About Page
    loadAbout({ commit }) {
      get(`${apiUrl}/page/about/`).then(body => {
        commit('updateAbout', body.data)
      })
    },
    // Load Console Cats
    loadConsoleCats({ commit }) {
      get(`${apiUrl}/category/console/`).then(body => {
        commit('updateConsoleCats', body.data)
      })
    },
    // Load Accessory Cats
    loadAccessoriesCats({ commit }) {
      get(`${apiUrl}/category/accessory/`).then(body => {
        commit('updateAccessoriesCats', body.data)
      })
    },
    // Load all Generations
    loadGenerations({ commit }) {
      get(`${apiUrl}/generations/`).then(body => {
        commit('updateGenerations', body.data)
      })
    },
    // Load all Brands
    loadBrands({ commit }) {
      get(`${apiUrl}/brands/all/`).then(body => {
        commit('updateBrands', body.data)
      })
    },
    // Load Game Media
    loadGameMedia({ commit }) {
      get(`${apiUrl}/media/`).then(body => {
        commit('updateGameMedia', body.data)
      })
    },
    // Load Consoles
    loadConsoles({ commit }, category = null) {
      get(`${apiUrl}/consoles/${category ? category + '/' : ''}`).then(body => {
        commit('updateConsoles', body.data)
      })
    },
    // Load Accessories
    loadAccessories({ commit }) {
      get(`${apiUrl}/accessories/`).then(body => {
        commit('updateAccessories', body.data)
      })
    },
    // Load Slideshow
    loadSlideshow({ commit }) {
      get(`${apiUrl}/slideshow/`).then(body => {
        commit('updateSlideshows', body.data.slides)
        commit('updateSlideshowBg', body.data.slideshowbg)
      })
    },
    // Load Social Media
    loadSocials({ commit }) {
      get(`${apiUrl}/socials/`).then(body => {
        commit('updateSocials', body.data)
      })
    },
    // Load FAQs
    loadFaqs({ commit }) {
      get(`${apiUrl}/faq/`).then(body => {
        commit('updateFaqs', body.data)
      })
    },

    // Set Console Filters
    setKeyword({ commit }, keyword) {
      commit('updateKeyword', keyword)
    },
    setCategory({ commit }, category) {
      commit('updateCategory', category)
    },
    setGeneration({ commit }, generation) {
      commit('updateGeneration', generation)
    },
    setBrand({ commit }, brand) {
      commit('updateBrand', brand)
    },
    setMedium({ commit }, medium) {
      commit('updateMedium', medium)
    },

    // Set Accessories Filters

    setAccessoriesCategory({ commit }, category) {
      commit('updateAccessoriesCategory', category)
    },
    setAccessoriesBrand({ commit }, brand) {
      commit('updateAccessoriesBrand', brand)
    },

    // Navigation Actions
    toggleNav({ commit }) {
      commit('toggleNav')
    },
    closeNav({ commit }) {
      commit('closeNav')
    },

    // Filters Actions
    toggleFilters({ commit }) {
      commit('toggleFilters')
    },
    closeFilters({ commit }) {
      commit('closeFilters')
    },
    clearFilters({ commit }) {
      commit('clearFilters')
    },
  },

  // D :: GETTERS
  getters: {
    // Console Filters
    consolesFilteredByAll() {
      return getByMedium(
        getByBrand(
          getByGeneration(
            getByCategory(
              getByKeyword(store.state.consoles, store.state.keyword),
              store.state.category
            ),
            store.state.generation
          ),
          store.state.brand
        ),
        store.state.medium
      )
    },
    consolesFilteredByKeyword() {
      let consoles = store.state.consoles
      return getByKeyword(consoles, store.state.keyword)
    },
    consolesFilteredByCategory() {
      return getByCategory(store.state.consoles, store.state.category)
    },
    consolesFilteredByGeneration() {
      return getByGeneration(store.state.consoles, store.state.generation)
    },
    consolesFilteredByBrand() {
      return getByBrand(store.state.consoles, store.state.brand)
    },
    consolesFilteredByMedium() {
      return getByMedium(store.state.consoles, store.state.medium)
    },
    consolesLatestReleased() {
      const latestReleasedItems = store.state.consoles.sort(
        (a, b) => new Date(b.releaseΥear) - new Date(a.releaseΥear)
      )
      return latestReleasedItems
    },
    // Accessories Filters
    accessoriesFilteredByAll() {
      return getByBrand(
        getByCategory(store.state.accessories, store.state.accessoriesCategory),
        store.state.accessoriesBrand
      )
    },
    accessoriesFilteredByKeyword() {
      let accessories = store.state.accessories
      return getByKeyword(accessories, store.state.keyword)
    },
    accessoriesFilteredByCategory() {
      return getByCategory(
        store.state.accessories,
        store.state.accessoriesCategory
      )
    },
    accessoriesFilteredByBrand() {
      return getByBrand(store.state.accessories, store.state.accessoriesBrand)
    },
  },
})
