var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page faq" },
    [
      _c("LayOneColumn", [
        _c("h1", { staticClass: "text-xs-center" }, [_vm._v("FAQ")]),
        _c(
          "div",
          { staticClass: "tabs" },
          _vm._l(_vm.faqs, function(faq) {
            return _c("div", { key: faq.index, staticClass: "tab" }, [
              _c("h2", { staticClass: "itemTitle" }, [
                _vm._v(_vm._s(faq.name))
              ]),
              _c("div", { domProps: { innerHTML: _vm._s(faq.descr) } })
            ])
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }