var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("LayWithSidebar", [
        _c(
          "div",
          { attrs: { slot: "sidebar" }, slot: "sidebar" },
          [
            _c(
              "filterPanel",
              { attrs: { title: "Consoles" } },
              [
                _c("categoriesFilter", {
                  attrs: { cats: _vm.categories, type: "consoles" }
                }),
                _c("generationsFilter"),
                _c("GenericFilter", {
                  attrs: {
                    items: _vm.$store.state.brands,
                    "selected-item": _vm.$store.state.brand || "all",
                    title: "Brands",
                    setting: "setBrand"
                  }
                }),
                _c("GenericFilter", {
                  attrs: {
                    items: _vm.$store.state.gameMedia,
                    "selected-item": _vm.$store.state.medium || "all",
                    title: "Game Medium",
                    setting: "setMedium"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { attrs: { slot: "content" }, slot: "content" },
          [
            _c("CategoryPageContent", {
              attrs: { items: _vm.items, "is-filter": "" }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }