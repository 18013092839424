var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layerWithSidebar" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            class: [
              { "jar-md-push-70": !_vm.sidebarLeft },
              "jar-md-30",
              "sticky",
              "sidebarOuter"
            ]
          },
          [_vm._t("sidebar")],
          2
        ),
        _c(
          "div",
          { class: [{ "jar-md-pull-30": !_vm.sidebarLeft }, "jar-md-70"] },
          [_c("div", { staticClass: "content" }, [_vm._t("content")], 2)]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }