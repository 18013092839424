var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer" }, [
    _c("div", { staticClass: "container-full" }, [
      _c("div", { staticClass: "row no-outer-pads v-align-md" }, [
        _c("div", { staticClass: "jar-md-90 jar-md-push-10" }, [
          _c("div", { staticClass: "footerMain" }, [
            _c("div", { staticClass: "row v-align-md" }, [
              _c(
                "div",
                { staticClass: "jar-md-25" },
                [_c("Logo", { staticClass: "text-xs-center" })],
                1
              ),
              _c("div", { staticClass: "jar-md-50" }),
              _c(
                "div",
                { staticClass: "jar-md-25 text-xs-center" },
                [_c("socialMedia")],
                1
              )
            ]),
            _c("div", { staticClass: "row v-align-md" }, [
              _c(
                "div",
                { staticClass: "jar-md-25 text-xs-center" },
                [_c("footerAbout")],
                1
              ),
              _c(
                "div",
                { staticClass: "jar-md-50" },
                [
                  _c("footerMenu", {
                    staticClass: "footer-menu-item",
                    attrs: { menus: _vm.menus }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "jar-md-25 text-xs-center" },
                [_c("footerSearch")],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "jar-md-25 text-xs-center" },
                [_c("copyrights")],
                1
              )
            ])
          ])
        ]),
        _c(
          "div",
          { staticClass: "jar-md-10 jar-md-pull-90" },
          [_c("credits")],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }