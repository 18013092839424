var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "consoleCategories" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.categories, function(category, index) {
          return _c(
            "div",
            { key: index, staticClass: "jar-md-33 categoryItem" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: { name: "consoles", params: { cat: category.alias } }
                  }
                },
                [
                  _c("div", { staticClass: "circleContainer" }, [
                    _c("div", { staticClass: "circle" }, [
                      _c(
                        "div",
                        {
                          staticClass: "back v-align-xs",
                          class: _vm._f("toCamelCase")(category.name)
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "backContainer text-xs-center" },
                            [
                              _c("img", {
                                attrs: {
                                  src: category.photo.url,
                                  alt: category.name
                                }
                              }),
                              _c("h3", [_vm._v(_vm._s(category.name))])
                            ]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "category-img v-align-xs front",
                          class: _vm._f("toCamelCase")(category.name)
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: category.photo.url,
                              alt: category.name
                            }
                          })
                        ]
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }