var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pageNotFound page" },
    [
      _c("LayOneColumn", [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("h1", { staticClass: "text-xs-center" }, [
              _vm._v("404 - " + _vm._s(_vm.title) + " Not Found")
            ]),
            _c("BaseIcon", {
              staticClass: "icon iconFlag",
              attrs: { icon: "flag" }
            }),
            _c("h3", { staticClass: "message" }, [_vm._v(_vm._s(_vm.message))]),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _c("BaseButton", {
                  staticClass: "button accentGreenBlue",
                  attrs: { link: "/", text: "Home" }
                }),
                _vm.link
                  ? _c("BaseButton", {
                      staticClass: "button accentGreenBlue",
                      attrs: { text: _vm.title, link: _vm.link }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }