var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "articleTile" },
    [
      _c(
        "router-link",
        { attrs: { to: { name: "post", params: { alias: _vm.item.alias } } } },
        [
          _c(
            "div",
            { staticClass: "photo" },
            [
              _c("BaseImage", {
                attrs: {
                  source: _vm.item.photo.medium,
                  alt: _vm.item.name,
                  background: ""
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "overlay" },
            [
              _c("BaseIcon", {
                staticClass: "icon iconLink",
                attrs: { icon: "link" }
              })
            ],
            1
          ),
          _c("div", { staticClass: "details" }, [
            _c("h3", { staticClass: "title" }, [_vm._v(_vm._s(_vm.item.name))]),
            _c(
              "h5",
              { staticClass: "subtitle" },
              [
                _vm._v("by " + _vm._s(_vm.item.author.username) + " in "),
                _vm._l(_vm.item.category, function(cat, index) {
                  return _c(
                    "router-link",
                    {
                      key: index,
                      staticClass: "category",
                      class: _vm._f("removeDash")(
                        _vm._f("toCamelCase")(cat.alias)
                      ),
                      attrs: {
                        to: { name: "posts", params: { cat: cat.alias } }
                      }
                    },
                    [_vm._v(_vm._s(cat.title))]
                  )
                }),
                _vm.item.category.length > 1
                  ? _c("i", [_vm._v(", ")])
                  : _vm._e()
              ],
              2
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }