<template>
  <div
    v-click-outside="closeSearch"
    :class="{active : searchOpened}"
    class="mainSearch text-xs-right"
  >
    <span
      class="searchIconOuter"
      @click="toggleSearch">
      <BaseIcon
        class="searchIcon"
        icon="search"/>
    </span>
    <div
      :class="{enter : enter}"
      class="searchInput">
      <div class="searchInputInner container">
        <div class="row">
          <div class="jar-xs-100">
            <input
              ref="search"
              :placeholder="searchPlaceholder"
              v-model="searchText"
              type="text"
              class="heading"
              @keyup.enter="enterSearch"
              @input="enableEnter"
            >
            <div
              class="enterIcon"
              @click="enterSearch">Enter</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainSearch',
  data: function() {
    return {
      searchOpened: false,
      searchText: '',
      searchPlaceholder: 'Search (Games, Articles, etc)',
      enter: false,
    }
  },
  methods: {
    toggleSearch() {
      this.searchOpened = !this.searchOpened
      this.$refs.search.focus()
      this.searchText = ''
      this.enableEnter()
    },
    closeSearch() {
      this.searchOpened = false
      this.searchText = ''
      this.enter = false
    },
    enableEnter() {
      if (this.searchText.length > 0) {
        this.enter = true
      } else {
        this.enter = false
      }
    },
    enterSearch() {
      if (this.searchText.length > 3) {
        this.$router.push({
          name: 'search',
          params: { input: this.searchText },
        })
        this.closeSearch()
      } else {
        this.$toast.warn({
          title: 'Search Warning',
          message: 'You have to add at least 4 characters',
          timeOut: 7000,
          position: 'top right',
          progressBar: true,
        })
      }
    },
  },
}
</script>

<style scoped lang="sass">
@import '~@assets/style/designs'

.searchIcon
  font-size: 1.2rem
  color: $c-alto-grey
  vertical-align: middle
  cursor: pointer
  transition: color $prim-trans-time $prim-trans-easing
.enterIcon
  position: absolute
  top: 50%
  right: 0
  padding: 5px
  margin-top: -11px
  line-height: 1
  cursor: pointer
  border: 1px solid $text-muted
  border-radius: $base-border-radius
  opacity: 0
  transition: opacity $prim-trans-time * 2 $prim-trans-easing
.searchInput
  position: fixed
  top: $header-height
  right: 0
  left: 0
  display: inline
  width: 100%
  height: auto
  padding: 50px 30px
  margin: 0 auto
  background: transparentize($c-ocean-green, 0.2)
  box-shadow: 0 2px 3px 0 transparentize($c-black, 0.1)
  transition: transform .3s ease-in-out
  transform: translateY(-50%) scale(1, 0)
  &.enter .enterIcon
    z-index: 10
    opacity: 1
input
  display: inline-block
  width: 100%
  font-size: 1rem
  color: $c-alto-grey
input::placeholder
  color: $text-muted
.mainSearch
  line-height: $header-height
  &.active
    .searchInput
      transform: translateY(0) scale(1, 1)
    .searchIcon
      color: $c-acc-green

@media (min-widtH: $md)
  .enterIcon
    right: unset
    left: 30%
</style>
