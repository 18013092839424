var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["generations", "text-xs-center", _vm.horizontal && "horizontal"]
    },
    [
      _c(
        "div",
        { staticClass: "generationsContainer" },
        _vm._l(_vm.generations, function(generation) {
          return _c(
            "div",
            {
              key: generation.name,
              class: [
                "generationsItem",
                { active: generation.name === _vm.currentGeneration }
              ]
            },
            [
              _c("div", [
                _c("div", { staticClass: "name heading" }, [
                  _vm._v(
                    "\n          " + _vm._s(generation.name) + "\n          "
                  ),
                  generation.name === _vm.currentGeneration
                    ? _c("div", [_vm._v("Generation")])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "years heading" }, [
                  _vm._v(
                    _vm._s(generation.startYear) +
                      " - " +
                      _vm._s(generation.endYear)
                  )
                ])
              ])
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }