var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page postCategoryPage" },
    [
      _c(
        "LayOneColumn",
        [
          _vm.posts && _vm.posts.length
            ? _c("div", [
                _c("h1", [_vm._v(_vm._s(_vm.postCat))]),
                _c(
                  "div",
                  { staticClass: "consoleList" },
                  [
                    _c("articleBlock", {
                      attrs: { items: _vm.posts, grid: "3" }
                    })
                  ],
                  1
                )
              ])
            : _c("NotFound", {
                attrs: { title: "No posts in category '" + _vm.postCat + "'" }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }