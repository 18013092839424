var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search page" },
    [
      _c("LayOneColumn", [
        _c("h1", { staticClass: "text-xs-center" }, [
          _vm._v("Search Results for\n      "),
          _c("span", { staticClass: "searchInput" }, [
            _vm._v(_vm._s(_vm.$route.params.input))
          ])
        ]),
        _vm.consolesFilteredByKeyword &&
        _vm.consolesFilteredByKeyword.length > 0
          ? _c("div", { staticClass: "row searchResults" }, [
              _c("div", { staticClass: "jar-xs-100" }, [
                _c("h2", { staticClass: "searchSectionTitle" }, [
                  _vm._v("Consoles")
                ])
              ]),
              _c("div", { staticClass: "jar-xs-100" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.consolesFilteredByKeyword, function(item, index) {
                    return index <
                      (_vm.isConsoleLoadAllEnabled
                        ? _vm.consolesFilteredByKeyword.length
                        : _vm.maxInitItems)
                      ? _c("ListItem", {
                          key: item.cat + item.id,
                          staticClass: "jar-sm-50 jar-md-25 item",
                          attrs: { item: item }
                        })
                      : _vm._e()
                  }),
                  1
                )
              ]),
              !_vm.isConsoleLoadAllEnabled &&
              _vm.maxInitItems < _vm.consolesFilteredByKeyword.length
                ? _c(
                    "div",
                    {
                      staticClass: "jar-xs-100",
                      on: {
                        click: function($event) {
                          _vm.isConsoleLoadAllEnabled = true
                        }
                      }
                    },
                    [
                      _c("BaseButton", {
                        staticClass: "accentGreenBlue",
                        attrs: { text: "Load All" }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          : _vm._e(),
        _vm.accessoriesFilteredByKeyword &&
        _vm.accessoriesFilteredByKeyword.length > 0
          ? _c("div", { staticClass: "row searchResults" }, [
              _c("div", { staticClass: "jar-xs-100" }, [
                _c("h2", { staticClass: "searchSectionTitle" }, [
                  _vm._v("Accessories")
                ])
              ]),
              _c("div", { staticClass: "jar-xs-100" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.accessoriesFilteredByKeyword, function(
                    item,
                    index
                  ) {
                    return index <
                      (_vm.isAccessoriesLoadAllEnabled
                        ? _vm.accessoriesFilteredByKeyword.length
                        : _vm.maxInitItems)
                      ? _c("ListItem", {
                          key: item.cat + item.id,
                          staticClass: "jar-sm-50 jar-md-25 item",
                          attrs: { item: item }
                        })
                      : _vm._e()
                  }),
                  1
                )
              ]),
              !_vm.isAccessoriesLoadAllEnabled &&
              _vm.maxInitItems < _vm.accessoriesFilteredByKeyword.length
                ? _c(
                    "div",
                    {
                      staticClass: "jar-xs-100",
                      on: {
                        click: function($event) {
                          _vm.isAccessoriesLoadAllEnabled = true
                        }
                      }
                    },
                    [
                      _c("BaseButton", {
                        staticClass: "accentGreenBlue",
                        attrs: { text: "Load All" }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          : _vm._e(),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "jar-xs-100" }, [
            _vm.$route.params.input.length < 4
              ? _c("h2", { staticClass: "text-xs-center" }, [
                  _vm._v("You have to enter at least 4 characters")
                ])
              : _vm._e(),
            _vm.consolesFilteredByKeyword.length === 0 &&
            _vm.accessoriesFilteredByKeyword.length === 0 &&
            _vm.$route.params.input.length > 3
              ? _c("h2", { staticClass: "text-xs-center" }, [
                  _vm._v("No results found")
                ])
              : _vm._e()
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }