<template>
  <div class="page">
    <LayWithSidebar>
      <div slot="sidebar">
        <filterPanel :title="'Consoles'">
          <categoriesFilter
            :cats="categories"
            :type="'consoles'"/>
          <generationsFilter/>
          <GenericFilter
            :items="$store.state.brands"
            :selected-item="$store.state.brand || 'all'"
            :title="'Brands'"
            :setting="'setBrand'"
          />
          <GenericFilter
            :items="$store.state.gameMedia"
            :selected-item="$store.state.medium || 'all'"
            :title="'Game Medium'"
            :setting="'setMedium'"
          />
        </filterPanel>
      </div>
      <div slot="content">
        <CategoryPageContent
          :items="items"
          is-filter/>
      </div>
    </LayWithSidebar>
  </div>
</template>

<script>
import LayWithSidebar from '@layouts/LayWithSidebar'
import filterPanel from '@partials/FiltersPanel'
import categoriesFilter from '@components/FiltersItems/CategoriesFilter'
import GenericFilter from '@components/FiltersItems/GenericFilter'
import generationsFilter from '@components/FiltersItems/GenerationsFilter'
import CategoryPageContent from '@partials/CategoryPageContent'

export default {
  name: 'ConsolesCategoryPage',
  meta() {
    return {
      title: ` ${this.title || ''} Category List`,
      meta: [
        { name: 'description', content: `Browse and find the ${this.title || 'consoles'} you are looking for.` },
      ],
    }
  },
  components: {
    LayWithSidebar,
    filterPanel,
    categoriesFilter,
    GenericFilter,
    generationsFilter,
    CategoryPageContent,
  },
  computed: {
    items() {
      return this.$store.getters.consolesFilteredByAll
    },
    categories() {
      return this.$store.state.consoleCats
    },
    alias() {
      return this.$route.params.cat || ''
    },
    title() {
      return this.categories.find(cat => cat.alias === this.alias || '').name
    }
  },
  watch: {
    $route() {
      this.updateStorageAttrs()
    },
  },
  created() {
    this.$store.dispatch('loadBrands')
    this.$store.dispatch('loadGameMedia')
    this.updateStorageAttrs()
  },
  methods: {
    updateStorageAttrs() {
      let catParam = this.$route.params.cat || ''
      this.$store.dispatch('setCategory', catParam)
    },
  },
}
</script>

<style scoped lang="sass">
@import '~@assets/style/designs'

</style>
