<template>
  <div class="footer">
    <div class="container-full">
      <div class="row no-outer-pads v-align-md">
        <div class="jar-md-90 jar-md-push-10">
          <div class="footerMain">
            <div class="row v-align-md">
              <div class="jar-md-25">
                <Logo class="text-xs-center"/>
              </div>
              <div class="jar-md-50"/>
              <div class="jar-md-25 text-xs-center">
                <socialMedia/>
              </div>
            </div>
            <div class="row v-align-md">
              <div class="jar-md-25 text-xs-center">
                <footerAbout/>
              </div>
              <div class="jar-md-50">
                <footerMenu
                  :menus="menus"
                  class="footer-menu-item"/>
              </div>
              <div class="jar-md-25 text-xs-center">
                <footerSearch/>
              </div>
            </div>
            <div class="row">
              <div class="jar-md-25 text-xs-center">
                <copyrights/>
              </div>
            </div>
          </div>
        </div>
        <div class="jar-md-10 jar-md-pull-90">
          <credits/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import credits from '@components/Credits'
import Logo from '@components/Logo'
import copyrights from '@components/Copyrights'
import footerAbout from '@components/FooterAbout'
import footerMenu from '@components/FooterMenu'
import socialMedia from '@components/SocialMedia'
import footerSearch from '@components/FooterSearch'

export default {
  name: 'TheFooter',
  components: {
    credits,
    Logo,
    copyrights,
    footerAbout,
    footerMenu,
    socialMedia,
    footerSearch,
  },
  data() {
    return {
      menus: [
        {
          menuItems: [
            { name: 'Privacy', link: '/page/privacy' },
            { name: 'Terms of use', link: '/page/terms-of-use' },
            { name: 'Collaboration', link: '/page/affiliation-collaboration' },
            { name: 'Credits', link: '/page/credits' },
            { name: 'Donations', link: '/page/donations' },
            { name: 'FAQ', link: '/faq' },
            { name: 'About', link: '/page/about' },
          ],
        },
        {
          menuItems: [
            { name: 'Handheld Consoles', link: '/consoles/handheld' },
            { name: 'Home Consoles', link: '/consoles/home-consoles' },
            { name: 'Accessories', link: '/accessories/controllers' },
            { name: 'Console News', link: '/posts/gaming-console-news' },
            { name: 'How to', link: '/posts/how-to' },
            { name: 'Gaming News', link: '/posts/gaming-news' },
          ],
        },
      ],
    }
  },
}
</script>

<style scoped lang="sass">
@import '~@assets/style/designs'

.footer
  background: $bg-color
  .footerMain
    padding: 45px 0
</style>
