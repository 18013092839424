
<template>
  <div class="articleTile">
    <router-link :to="{name: 'post', params: {alias: item.alias}}">
      <div class="photo">
        <BaseImage
          :source="item.photo.medium"
          :alt="item.name"
          background/>
      </div>
      <div class="overlay">
        <BaseIcon
          :icon="'link'"
          class="icon iconLink"/>
      </div>
      <div class="details">
        <h3 class="title">{{ item.name }}</h3>
        <h5 class="subtitle">by {{ item.author.username }} in <router-link
          v-for="(cat, index) in item.category"
          :class="cat.alias|toCamelCase|removeDash"
          :key="index"
          :to="{name: 'posts', params: {cat: cat.alias}}"
          class="category">{{ cat.title }}</router-link><i v-if="item.category.length > 1">, </i></h5>
      </div>

    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ArticleTile',
  props: {
  	item: {
  		type: Object,
  		default: () => {}
    },
    excerpt: {
      type: Number,
      default: 120
    }
  },
  computed: {
    dots() {
      return this.excerpt === 0 ? '' : '...'
    }
  }
}
</script>

<style lang="sass" scoped>
@import '~@assets/style/designs'

.details
  position: absolute
  bottom: $base-spacing / 2
  left: $base-spacing / 2
  z-index: 11
  width: calc(100% - #{$base-spacing})
  padding: $base-spacing
  background: transparentize($c-black, 0.3)
  border-radius: $base-border-radius
  transition: min-height .3s ease-in-out

.overlay
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 10
  display: grid
  align-items: center
  width: 100%
  height: 100%
  margin: 0 auto
  background: transparentize($c-acc-green, 0.5)
  opacity: 0
  transition: opacity .3s ease-out
  .icon
    margin: -59px auto 0
    font-size: 50px
    transition: transform .3s ease-out
.articleTile
  position: relative
  height: 350px
  box-shadow: $base-shadow
  &:hover
    .overlay
      opacity: 1
    .icon
      transform: scale(1.3)



.title
  min-height: 32px
  margin-bottom: $base-spacing / 2
  text-transform: uppercase

  @extend %contentSubtitleL

.subtitle
  margin-bottom: $base-spacing
  text-transform: uppercase

  @extend %contentSubtitleM

.category
  border-bottom: 2px solid
  &.consoleNews
    border-color: $c-acc-green
  &.gamingNews
    border-color: $c-acc-blue

.excerpt
  margin-bottom: 0

.photo
  position: absolute
  top: 0
  right: 0
  left: 0
  display: block
  min-width: 100%
  min-height: 100%

</style>
