var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeSearch,
          expression: "closeSearch"
        }
      ],
      staticClass: "mainSearch text-xs-right",
      class: { active: _vm.searchOpened }
    },
    [
      _c(
        "span",
        { staticClass: "searchIconOuter", on: { click: _vm.toggleSearch } },
        [
          _c("BaseIcon", {
            staticClass: "searchIcon",
            attrs: { icon: "search" }
          })
        ],
        1
      ),
      _c("div", { staticClass: "searchInput", class: { enter: _vm.enter } }, [
        _c("div", { staticClass: "searchInputInner container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "jar-xs-100" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchText,
                    expression: "searchText"
                  }
                ],
                ref: "search",
                staticClass: "heading",
                attrs: { placeholder: _vm.searchPlaceholder, type: "text" },
                domProps: { value: _vm.searchText },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.enterSearch($event)
                  },
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.searchText = $event.target.value
                    },
                    _vm.enableEnter
                  ]
                }
              }),
              _c(
                "div",
                { staticClass: "enterIcon", on: { click: _vm.enterSearch } },
                [_vm._v("Enter")]
              )
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }