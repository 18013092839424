<template>
  <div class="credits text-xs-center">
    <ToTop/>
    <div class="creditsArea">
      <div>Developed with
        <BaseIcon :icon="'heart'"/> and tons of
        <div class="coffee">
          <span class="smoke">
            <BaseIcon :icon="'fire'"/>
          </span>
          <BaseIcon :icon="'coffee'"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToTop from '@components/ToTop.vue'

export default {
  name: 'Credits',
  components: { ToTop },
}
</script>

<style scoped lang="sass">
@import '~@assets/style/designs'

.credits
	padding-bottom: 30px
	font-size: 1rem
	color: $c-black
	text-transform: uppercase
	background: $c-alto-grey
.creditsArea
	.icon
		margin: 10px 0
.coffee
	position: relative
	.smoke
		position: absolute
		right: 0
		bottom: 6px
		left: 0
		opacity: 0.2
		animation: smoke 2s infinite linear

@keyframes smoke
	0%
		transform: translateY(0)
		opacity: 0.2

	100%
		transform: translateY(-30px)
		opacity: 0

@media (min-width: $md)
	.credits
		padding: 80px 15px
		word-spacing: 100vw
</style>
