var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mainNavbar" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "navbarContent" },
        [
          _c("mainMenu"),
          _c("Logo", { staticClass: "logo" }),
          _c("mainSearch", { staticClass: "search" })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }