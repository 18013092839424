<script>
export default {
  name: 'ConsolePageMedia',
  props: {
    media: {
      type: Array,
      default: () => [],
    }
  }
}
</script>

<template>
  <div
    v-if="media"
    class="media characteristic">
    <div
      v-tooltip="`Medium: ${medium}`"
      v-for="(medium, index) in media"
      :key="index"
      class="mediaListItem"
    >
      <BaseIcon
        v-if="medium==='Cartridge'"
        class="mediaIcon cardMedia"
        icon="sd-card"
      />
      <BaseIcon
        v-else-if="medium==='Cloud'"
        class="mediaIcon cloudMedia"
        icon="cloud"
      />
      <BaseIcon
        v-else-if="medium==='SmartMedia Cards' || medium==='MultiMedia Card' || medium==='SD Card'"
        class="mediaIcon smartMediaCardsMedia"
        icon="sd-card"
      />
      <BaseIcon
        v-else-if="medium==='Built in' || medium==='Flash memory'"
        class="mediaIcon builtInMedia"
        icon="microchip"
      />
      <BaseIcon
        v-else-if="medium==='Magnetic Tape' || medium==='Compact Cassette'"
        class="mediaIcon magneticTapeMedia"
        icon="tape"
      />
      <BaseIcon
        v-else-if="medium==='CD'"
        class="mediaIcon cdMedia"
        icon="compact-disc"
      />
      <BaseIcon
        v-else-if="medium==='DVD'"
        class="mediaIcon dvdMedia"
        icon="compact-disc"
      />
      <BaseIcon
        v-else-if="medium==='Blue Ray'"
        class="mediaIcon blueRayMedia"
        icon="compact-disc"
      />
      <BaseIcon
        v-else-if="medium==='HuCard'"
        class="mediaIcon huCardMedia"
        icon="sd-card"
      />
      <BaseIcon
        v-else-if="medium==='USB external storage'"
        class="mediaIcon"
        icon="usb"
      />
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~@assets/style/designs'

.mediaListItem
  position: relative
  display: inline-block
.mediaListItem~.mediaListItem
  margin-left: $base-spacing / 2
.mediaIcon
  font-size: 20px
.cardMedia
  color: $c-boulder-grey
.cloudMedia
  color: $c-white
</style>
