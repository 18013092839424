var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["sectionTitle", _vm.iconAccent] }, [
    _c("h2", [
      _vm.icon
        ? _c("span", {
            staticClass: "icon",
            domProps: { innerHTML: _vm._s(_vm.icon) }
          })
        : _vm._e(),
      _c("span", { domProps: { innerHTML: _vm._s("&nbsp") } }),
      _c("span", {
        staticClass: "title",
        domProps: { innerHTML: _vm._s(_vm.title) }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }