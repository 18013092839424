var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "itemsOverflowedList",
      style: "max-height:" + _vm.height + "px"
    },
    _vm._l(_vm.items, function(item, index) {
      return (_vm.maxItems
      ? index < _vm.maxItems
      : index < _vm.items.length)
        ? _c(
            "div",
            { key: item.releaseΥear + item.id, staticClass: "item" },
            [
              _c(
                "router-link",
                {
                  staticClass: "link",
                  attrs: {
                    to: { name: _vm.type, params: { alias: item.alias } }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      class: [
                        "itemInner",
                        { active: _vm.currentItem === item.name }
                      ]
                    },
                    [
                      _c("div", { staticClass: "details heading" }, [
                        _c("div", { staticClass: "category" }, [
                          _vm._v(_vm._s(item.cat.title))
                        ]),
                        _c("div", { staticClass: "detailsBottom" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(item.name))
                          ]),
                          _c("div", { staticClass: "brand" }, [
                            _vm._v(_vm._s(item.brand))
                          ])
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "image" },
                        [
                          _c("BaseImage", {
                            staticClass: "imageInner",
                            attrs: {
                              source: item.photo.thumb,
                              alt: item.photo.alt
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }