var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["page", _vm.$route.params.name] },
    [
      _c("LayOneColumn", [
        _c("h1", { staticClass: "text-xs-center" }, [
          _vm._v(_vm._s(_vm.currentPage.name))
        ]),
        _c("div", { domProps: { innerHTML: _vm._s(_vm.currentPage.descr) } })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }