<script>
export default {
  name: 'GenericFilter',
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
    selectedItem: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'Filter',
    },
    setting: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      listActive: false,
    }
  },
  methods: {
    toggleList() {
      this.listActive = !this.listActive
    },
    closeList() {
      this.listActive = false
    },
    selectItem(itemName) {
      this.$store.dispatch(
        this.setting,
        itemName.toLowerCase().replace(' ', '-')
      )
    },
  },
}
</script>

<template>
  <div v-if="items.length > 0">
    <div class="filter">
      <h3 class="title">{{ title }}</h3>
      <div
        v-click-outside="closeList"
        class="filterSelect text-xs-center"
        @click="toggleList">
        <div class="selectedHeadingArea">
          <h5 class="selectedTitle">{{ selectedItem | dashspace }}</h5>
          <BaseIcon
            :class="{active: listActive}"
            class="caret"
            icon="caret-down"/>
        </div>
        <div
          :class="{active: listActive}"
          class="selectList"
          @click="$store.dispatch('closeFilters')"
        >
          <div
            :class="{ selected: selectedItem === 'all' }"
            class="option"
            @click="selectItem('')"
          >All {{ title }}</div>
          <div
            v-for="item in items"
            :class="{selected: item.name.toLowerCase().replace(' ', '-') === selectedItem}"
            :key="item.id"
            class="option"
            @click="selectItem(item.name)"
          >{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~@assets/style/designs'

.filter
  margin: 0 0 $base-spacing * 2 0
.title
  margin-bottom: $base-spacing
  font-size: 1.1rem
  font-weight: normal
  text-transform: uppercase

.selectedHeadingArea
  position: relative
  z-index: 10
  cursor: pointer
.selectedTitle
  width: 100%
  margin-bottom: 0
  line-height: 30px
  color: $bg-color
  text-transform: uppercase
  background: $c-alto-grey
  border-radius: 20px
.selectList
  position: relative
  top: 5px
  width: 100%
  height: 0
  overflow-y: auto
  line-height: 30px
  background: #2F3E45
  transition: height $prim-trans-time $prim-trans-easing
  &.active
    height: 200px
.option
  font-size: 0.8rem
  cursor: pointer
  &.selected
    background: $c-acc-green-dark
  &:hover
    background: $c-acc-green

.caret
  position: absolute
  top: 6px
  right: 15px
  font-size: 1rem
  color: $bg-color
  transition: all $prim-trans-time $prim-trans-easing
  &.active
    opacity: 0
    transform: translateY(30px)
</style>
