<script>
import { get } from '@services/apiUtils'
import mainSlideShow from '@components/MainSlideShow'
import LayWithSidebar from '@layouts/LayWithSidebar'
import ItemsOverflowedList from '@partials/ItemsOverflowedList'
import consoleCategories from '@partials/ConsoleCategories'
import articleBlock from '@components/Article/ArticleBlock'
import { Carousel, Slide } from 'vue-carousel'
import articleTile from '@components/Article/ArticleTile'

export default {
  name: 'MainPage',
  meta: {
    title: 'Everything About Gaming Consoles',
    meta: [
      { name: 'description', content: 'In CONSALL.EU you can find anything About gaming, gaming consoles, gaming accessories, news, DIYs and many more. Join our community.' },
    ],
  },
  components: {
    mainSlideShow,
    LayWithSidebar,
    ItemsOverflowedList,
    consoleCategories,
    articleBlock,
    articleTile,
    Carousel,
    Slide
  },
  data() {
    return {
      consoleNewsApi: [],
      consoleHistoryApi: [],
      retroConsolesApi: [],
      retroLimit: 10
    }
  },
  computed: {
    retroConsoles() {
      return this.retroConsolesApi
    },
    consoleNewsPosts() {
      return this.consoleNewsApi
    },
    consoleHistoryPosts() {
      return this.consoleHistoryApi
    }
  },
  created() {
    get(`${process.env.VUE_APP_API_BASE_URL}/posts/gaming-console-news/`)
      .then(body => {
        this.consoleNewsApi = body.data
      })

    get(`${process.env.VUE_APP_API_BASE_URL}/posts/gaming-console-history/`)
      .then(body => {
        this.consoleHistoryApi = body.data
      })

    get(`${process.env.VUE_APP_API_BASE_URL}/consoles/retro/?limit=${this.retroLimit}`)
      .then(body => {
        this.retroConsolesApi = body.data;
      })

  },
}
</script>

<template>
  <div class="home-content">
    <mainSlideShow/>
    <LayWithSidebar
      :sidebar-left="false"
      class="mainContent">
      <div slot="sidebar">
        <BaseSectionTitle
          :icon-accent="'pink'"
          :title="`<span class='accentPink'>Retro</span> Consoles
          `"
          icon="<span style='transform: scale(0.7); display: inline-block'>△</span>"/>
        <ItemsOverflowedList
          :max-items="retroLimit"
          :items="retroConsoles"
          :height="800"/>
      </div>
      <div
        v-if="consoleNewsPosts && consoleNewsPosts.length"
        slot="content">
        <BaseSectionTitle
          :icon-accent="'blue'"
          :title="`Console - <span class='accentBlue'>News</span>`"
          icon="<span style='transform: scale(0.7); display: inline-block'>&#9447;</span>"/>
        <articleBlock
          :items="consoleNewsPosts"
          :grid="'2'"/>
      </div>
    </LayWithSidebar>
    <consoleCategories/>
    <div
      v-if="consoleHistoryPosts && consoleHistoryPosts.length"
      class="container">
      <div class="row">
        <div class="jar-xs-100">
          <div class="consoleNews">
            <BaseSectionTitle
              :icon-accent="'green'"
              :title="`Console - <span class='accentGreen'>History</span>`"
              icon="<span style='transform: scale(0.7); display: inline-block'>△</span>"/>
            <Carousel
              :per-page-custom="[[0, 1], [776, 2], [992, 3]]"
              :pagination-size="8"
              :pagination-padding="5"
              :pagination-enabled="true"
              pagination-active-color="#51A15C"
              class="newsCarousel"
            >
              <Slide
                v-for="(item, index) in consoleHistoryPosts"
                :key="index + '0'"
                class="newsSlide">
                <articleTile
                  :item="item"
                  :excerpt="0"/>
              </Slide>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~@assets/style/designs'

.mainContent
  margin-bottom: $section-margin-bottom
.consoleNews
  position: relative
  left: $base-spacing
  width: calc(100% - #{$base-spacing * 2})
  margin-bottom: $section-margin-bottom
.newsCarousel
  margin: 0 ($base-spacing / -2)
.newsSlide
  padding: 0 ($base-spacing / 2)
</style>
