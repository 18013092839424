<script>
export default {
  name: 'CategoriesFilter',
  props: {
    cats: {
      type: Array,
      default: () => {
        return []
      },
    },
    type: {
      type: String,
      default: '',
    },
  },
  methods: {
    updateCatRoute(cat) {
      this.$router.push({ params: { cat: cat } })
    },
  },
}
</script>

<template>
  <div class="filterItem filterCategories">
    <div class="row">
      <div
        v-for="category in cats"
        :key="category.id"
        class="jar-xs-33 text-xs-center">
        <div
          class="categoryItem"
          @click="updateCatRoute(category.alias)">
          <div
            v-tooltip="category.name"
            class="categoryImage">
            <img
              :src="category.photo.url"
              :alt="`${category.name} Category`"
              :class="['image', {active : category.alias == $route.params.cat }]"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass">
@import '~@assets/style/designs'

.filterCategories
.categoryImage
  max-width: 40px
  height: 40px
  margin: $base-spacing auto
  line-height: 40px
  vertical-align: middle
.image
  display: inline
  max-height: 100%
  &.active
    filter: drop-shadow(0 0 0.75rem $c-acc-green)
.categoryItem
  position: relative
  cursor: pointer
  &:hover
    .tooltip
      z-index: 20
      margin: auto
      opacity: 1
</style>
