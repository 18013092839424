var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    _vm._l(_vm.menus, function(menu, index) {
      return _c(
        "ul",
        { key: index },
        _vm._l(menu.menuItems, function(menuItem, index) {
          return _c(
            "li",
            { key: index, staticClass: "item" },
            [
              _c("router-link", { attrs: { to: menuItem.link } }, [
                _vm._v(_vm._s(menuItem.name))
              ])
            ],
            1
          )
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }