var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "toTop",
      on: {
        click: function($event) {
          return _vm.scrollTo(0, null, "smooth")
        }
      }
    },
    [
      _c("BaseIcon", {
        staticClass: "toTopIcon",
        attrs: { icon: "long-arrow-alt-up" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }