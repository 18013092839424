var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mainButton" }, [
    _vm.link
      ? _c("a", { staticClass: "buttonText", attrs: { href: _vm.link } }, [
          _vm._v(_vm._s(_vm.text))
        ])
      : _c("span", { staticClass: "buttonText" }, [_vm._v(_vm._s(_vm.text))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }