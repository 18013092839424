<template>
  <div class="copyrights">
    <span>&copy; {{ `${year} - ${currentYear}` }}, All rights reserved, Consall Team.</span>
  </div>
</template>

<script>
export default {
  name: 'Copyrights',
  data() {
    return {
      year: 2018,
    }
  },
  computed: {
    currentYear() {
      const now = new Date()
      return now.getFullYear()
    }
  }
}
</script>

<style scoped lang="sass">
@import "~@assets/style/designs"

.copyrights
  margin-top: 24px
  color: $c-acc-green
</style>
