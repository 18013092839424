
<template>
  <div class="row articleBlock">
    <div
      v-for="(item, index) in items"
      :class="[gridCount, 'gridItem']"
      :key="index">
      <articleTile :item="item"/>
    </div>
  </div>
</template>

<script>
import articleTile from '@components/Article/ArticleTile'

export default {
  name: 'ArticleBlock',
  components: {
    articleTile,
  },
  props: {
    grid: {
      type: String,
      default: '2',
    },
    items: {
      type: Array,
      default: () => {}
    },
  },
  computed: {
    gridCount() {
      return this.grid === '2'
        ? 'jar-md-50'
        : this.grid === '3'
        ? 'jar-md-33'
        : this.grid === '4'
        ? 'jar-md-25'
        : this.grid === '5'
        ? 'jar-md-20'
        : 'jar-md-100'
    },
  },
}
</script>

<style lang="sass" scoped>
@import '~@assets/style/designs'

.row.articleBlock
  margin-right: $base-spacing / 2 * -1
  margin-left: $base-spacing / 2 * -1

  .gridItem
    padding-right: $base-spacing / 2
    padding-left: $base-spacing / 2
    margin-bottom: $base-spacing
</style>
