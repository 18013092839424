<template>
  <div
    :style="`max-height:${height}px`"
    class="itemsOverflowedList">
    <div
      v-for="(item, index) in items"
      v-if="maxItems ? index < maxItems : index < items.length"
      :key="item.releaseΥear+item.id"
      class="item"
    >
      <router-link
        :to="{name: type, params:{alias: item.alias}}"
        class="link">
        <div :class="['itemInner', {active : currentItem === item.name}]">
          <div class="details heading">
            <div class="category">{{ item.cat.title }}</div>
            <div class="detailsBottom">
              <div class="name">{{ item.name }}</div>
              <div class="brand">{{ item.brand }}</div>
            </div>
          </div>
          <div
            class="image">
            <!-- <div
              :style="`background-image: url(${item.photo.thumb})`"
              class="bg"/> -->
            <BaseImage
              :source="item.photo.thumb"
              :alt="item.photo.alt"
              class="imageInner"/>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemsOverflowedList',
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
    type: {
      type: String,
      default: 'console',
    },
    currentItem: {
      type: String,
      default: '',
      required: false,
    },
    height: {
      type: Number,
      default: 550,
      required: false,
    },
    maxItems: {
      type: Number,
      default: null,
    },
  },
}
</script>

<style lang="sass" scoped>
@import '~@assets/style/designs'

.itemsOverflowedList
  overflow-x: hidden
  overflow-y: auto

.itemsOverflowedList::-webkit-scrollbar-track
  background-color: $c-boulder-grey
.itemsOverflowedList::-webkit-scrollbar
  width: 4px
  background: $c-boulder-grey
.itemsOverflowedList::-webkit-scrollbar-thumb
  background: $c-acc-green-dark

.item
  position: relative
  margin-bottom: $base-spacing / 2
.item:last-of-type
  margin-bottom: 0
.link
  display: block
.itemInner
  position: relative
  display: grid
  grid-template-columns: 1fr 95px
  padding: $base-spacing / 2
  background: darken($c-bunker-black, 5%)
  &::before
    position: absolute
    top: 0
    left: 0
    z-index: 0
    width: 100%
    height: 100%
    content: ''
    opacity: 0
    transition: opacity .3s ease-in-out
  &:hover, &.active
    &::before
      opacity: 0.5
.details
  position: relative
  z-index: 1
  vertical-align: top

// .bg
//   position: absolute
//   top: 0
//   left: 0
//   z-index: 0
//   width: 100%
//   height: 100%
//   filter: blur(15px)
//   background-size: 100% 100%

.image
  position: relative
  z-index: 1
  height: 95px
  padding: $base-spacing / 2
  overflow: hidden
  border-radius: 50%
  .imageInner
    position: relative
    width: auto
    max-width: 100%
    height: 100%

.item:nth-of-type(4n+1)
  .image, .itemInner::before
    background: $c-sunglo-red
.item:nth-of-type(4n+2)
  .image, .itemInner::before
    background: $c-shamrock-green
.item:nth-of-type(4n+3)
  .image, .itemInner::before
    background: $c-mountain-meadow
.item:nth-of-type(4n+4)
  .image, .itemInner::before
    background: $c-curious-blue


.category
  font-size: 14px
  line-height: 16px
.detailsBottom
  position: absolute
  bottom: 0
  left: 0
.name
  font-size: 16px
  line-height: 20px
.brand
  font-size: 16px
  line-height: 20px

</style>
