var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notFound" },
    [
      _c("h1", [_vm._v(_vm._s(_vm.title))]),
      _c("BaseIcon", { staticClass: "icon", attrs: { icon: "flag" } }),
      _c("h3", { staticClass: "message" }, [_vm._v(_vm._s(_vm.message))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }