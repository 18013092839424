import { render, staticRenderFns } from "./PostsCategoryPage.vue?vue&type=template&id=00335a2c&scoped=true&"
import script from "./PostsCategoryPage.vue?vue&type=script&lang=js&"
export * from "./PostsCategoryPage.vue?vue&type=script&lang=js&"
import style0 from "./PostsCategoryPage.vue?vue&type=style&index=0&id=00335a2c&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00335a2c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/pmanikas.social/consall/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('00335a2c')) {
      api.createRecord('00335a2c', component.options)
    } else {
      api.reload('00335a2c', component.options)
    }
    module.hot.accept("./PostsCategoryPage.vue?vue&type=template&id=00335a2c&scoped=true&", function () {
      api.rerender('00335a2c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/PostsCategoryPage.vue"
export default component.exports