<script>
import LayOneColumn from '@layouts/LayOneColumn'
import articleBlock from '@components/Article/ArticleBlock'
import NotFound from '@components/NotFound'
import { get } from '@services/apiUtils'

export default {
  name: 'PostsCategoryPage',
  page() {
    return {
      title: `${this.postCat || 'Article Category will be available in a while'}`,
      meta: [
        { name: 'description', content: `Browse the list and find your favourite ${this.postCat} articles here.` },
      ],
    }
  },
  components: {
    LayOneColumn,
    articleBlock,
    NotFound
  },
  data() {
    return {
      postData: [],
      headersTitle: ''
    }
  },
  computed: {
    postCat() {
      return this.headersTitle
    },
    alias() {
      return this.$route.params.cat
    },
    posts() {
      return this.postData && this.postData.length && this.postData || []
    }
  },
  watch: {
    alias() {
      this.get()
    }
  },
  created() {
    this.get()
  },
  methods: {
    get() {
      get(`${process.env.VUE_APP_API_BASE_URL}/posts/${this.alias}/`)
        .then(body => {
          this.headersTitle = body.headers['x-category-title'] || ''
          this.postData = body.data
        })
        .catch(_error => {
          this.postData = []
        })
    }
  },

}
</script>

<template>
  <div class="page postCategoryPage">
    <LayOneColumn>
      <div v-if="posts && posts.length">
        <h1>{{ postCat }}</h1>
        <div class="consoleList">
          <articleBlock
            :items="posts"
            :grid="'3'"/>
        </div>
      </div>
      <NotFound
        v-else
        :title="`No posts in category '${postCat}'`"/>
    </LayOneColumn>
  </div>
</template>

<style lang="sass" scoped>
@import '~@assets/style/designs'

</style>
