import MainPage from '@pages/MainPage.vue'
import ConsolesCategoryPage from '@pages/ConsolesCategoryPage.vue'
import AccessoriesCategoryPage from '@pages/AccessoriesCategoryPage.vue'
import SingleConsolePage from '@pages/SingleConsolePage.vue'
import SingleAccessoryPage from '@pages/SingleAccessoryPage.vue'
import SinglePostPage from '@pages/SinglePostPage.vue'
import PostsCategoryPage from '@pages/PostsCategoryPage.vue'
import InfoPage from '@pages/InfoPage.vue'
import FaqPage from '@pages/FaqPage.vue'
import SearchPage from '@pages/SearchPage.vue'
import PageNotFound from '@pages/PageNotFound.vue'
import ListItemsPage from '@pages/ListItemsPage.vue'

export default [
  {
    path: '*',
    redirect: '404'
  },
  {
    path: '/',
    name: 'main',
    component: MainPage
  },
  {
    path: '/consoles/:cat/:gen?',
    name: 'consoles',
    component: ConsolesCategoryPage,
    props: true,
  },
  {
    path: '/accessories/:cat',
    name: 'accessories',
    component: AccessoriesCategoryPage,
    props: true,
  },
  {
    path: '/console/:alias',
    name: 'console',
    component: SingleConsolePage
  },
  {
    path: '/accessory/:alias',
    name: 'accessory',
    component: SingleAccessoryPage
  },
  {
    path: '/posts/:cat?',
    name: 'posts',
    component: PostsCategoryPage
  },
  {
    path: '/post/:alias',
    name: 'post',
    component: SinglePostPage
  },
  {
    path: '/page/:name',
    name: 'page',
    component: InfoPage
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqPage
  },
  {
    path: '/search/:input',
    name: 'search',
    component: SearchPage
  },
  {
    path: '/list/:type/:characteristic?',
    name: 'list-item',
    component: ListItemsPage,
    props: true
  },
  {
    path: '/404',
    name: '404',
    component: PageNotFound,
    props: true
  },
]
