var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["socialMedia", { header: _vm.header }] }, [
    _c(
      "ul",
      _vm._l(_vm.socials, function(social, index) {
        return _c("li", { key: index, staticClass: "item" }, [
          _c(
            "a",
            {
              staticClass: "link",
              attrs: { href: social.link, title: social.name }
            },
            [_c("BaseIcon", { attrs: { icon: ["fab", social.icon] } })],
            1
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }