var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.media
    ? _c(
        "div",
        { staticClass: "media characteristic" },
        _vm._l(_vm.media, function(medium, index) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: "Medium: " + medium,
                  expression: "`Medium: ${medium}`"
                }
              ],
              key: index,
              staticClass: "mediaListItem"
            },
            [
              medium === "Cartridge"
                ? _c("BaseIcon", {
                    staticClass: "mediaIcon cardMedia",
                    attrs: { icon: "sd-card" }
                  })
                : medium === "Cloud"
                ? _c("BaseIcon", {
                    staticClass: "mediaIcon cloudMedia",
                    attrs: { icon: "cloud" }
                  })
                : medium === "SmartMedia Cards" ||
                  medium === "MultiMedia Card" ||
                  medium === "SD Card"
                ? _c("BaseIcon", {
                    staticClass: "mediaIcon smartMediaCardsMedia",
                    attrs: { icon: "sd-card" }
                  })
                : medium === "Built in" || medium === "Flash memory"
                ? _c("BaseIcon", {
                    staticClass: "mediaIcon builtInMedia",
                    attrs: { icon: "microchip" }
                  })
                : medium === "Magnetic Tape" || medium === "Compact Cassette"
                ? _c("BaseIcon", {
                    staticClass: "mediaIcon magneticTapeMedia",
                    attrs: { icon: "tape" }
                  })
                : medium === "CD"
                ? _c("BaseIcon", {
                    staticClass: "mediaIcon cdMedia",
                    attrs: { icon: "compact-disc" }
                  })
                : medium === "DVD"
                ? _c("BaseIcon", {
                    staticClass: "mediaIcon dvdMedia",
                    attrs: { icon: "compact-disc" }
                  })
                : medium === "Blue Ray"
                ? _c("BaseIcon", {
                    staticClass: "mediaIcon blueRayMedia",
                    attrs: { icon: "compact-disc" }
                  })
                : medium === "HuCard"
                ? _c("BaseIcon", {
                    staticClass: "mediaIcon huCardMedia",
                    attrs: { icon: "sd-card" }
                  })
                : medium === "USB external storage"
                ? _c("BaseIcon", {
                    staticClass: "mediaIcon",
                    attrs: { icon: "usb" }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }