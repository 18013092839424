<script>
import { get } from '@services/apiUtils'
import LayOneColumn from '@layouts/LayOneColumn'

export default {
  name: 'SinglePostPage',
  meta() {
    return {
      title: this.title || 'Article Title will be available soon',
      meta: [
        { name: 'description', content: `${this.post && this.post.description || 'Find more about ' + this.title}` },
      ],
    }
  },
  components: {
    LayOneColumn
  },
  data() {
    return {
      post: () => {},
      apiUrl: process.env.VUE_APP_API_BASE_URL
    }
  },
  computed: {
    alias() {
      return this.$route.params.alias
    },
    title() {
      return this.post && this.post.name
    }
  },
  watch: {
    alias() {
      this.get()
    }
  },
  created() {
    this.get()
  },
  methods: {
    get() {
      get(`${this.apiUrl}/post/${this.alias}/`)
        .then(body => {
            this.post = body.data
        })
        .catch(_error => {
          this.$router.push({name: 404, params: { message: 'Try another post', title: 'Post', link: `/posts/console-news` }})
        })
    }
  },
}
</script>

<template>
  <article class="page postPage">
    <LayOneColumn>
      <div class="postTop">
        <header class="postTitleContainer">
          <h1 class="text-xs-center postTitle">{{ post.name }}</h1>
        </header>
        <div class="postDetails">
          <div class="postCategories">
            <router-link
              v-for="(category, i) in post.cat"
              :key="category+i"
              :to="{name: 'posts', params: {cat: category.alias}}">
              <BaseLabel size="medium">{{ category.name }}</BaseLabel>
            </router-link>
          </div>
          <div class="postAuthor">Written by <span class="postAuthorName">{{ post && post.author && post.author.username }}</span></div>
        </div>
      </div>
      <BaseImage
        :source="post && post.photo && post.photo.large"
        :alt="post && post.photo && post.photo.alt"
        class="postImage"/>
      <div
        class="postDescription"
        v-html="post && post.description"/>
    </LayOneColumn>
  </article>
</template>

<style scoped lang="sass">
@import '~@assets/style/designs'

.post
  &Page
    margin-top: $section-spacing
    p
      color: red
  &Top
    box-shadow: $base-shadow
  &TitleContainer
    display: grid
    align-items: center
    background: $ebony-gradient
  &Details
    display: grid
    grid-template-columns: 1fr 200px
    align-items: center
    height: $header-height
    padding: 0 $base-spacing
    margin-bottom: $section-spacing
    background: $c-ebony-gray
    border-top: 1px solid $c-bunker-black
    @media (min-width: $md)
      div~div
        border-left: 1px solid $c-bunker-black
  &Author
    font-family: monospace
    font-size: 1rem
    line-height: $header-height
    text-align: center
    &Name
      font-weight: bold
  &Image
    position: relative
  &Description
    margin-top: $section-spacing

img
  max-width: 100%
</style>
