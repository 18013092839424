<template>
  <div class="consoleCategories">
    <div class="container">
      <div class="row">
        <div
          v-for="(category, index) in categories"
          :key="index"
          class="jar-md-33 categoryItem">
          <router-link :to="{ name: 'consoles', params:{ cat: category.alias }}">
            <div class="circleContainer">
              <div class="circle">
                <div
                  :class="category.name | toCamelCase"
                  class="back v-align-xs">
                  <div class="backContainer text-xs-center">
                    <img
                      :src="category.photo.url"
                      :alt="category.name">
                    <h3>{{ category.name }}</h3>
                  </div>
                </div>
                <div
                  :class="category.name | toCamelCase"
                  class="category-img v-align-xs front">
                  <img
                    :src="category.photo.url"
                    :alt="category.name">
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ConsoleCategories',

  computed: {
    categories() {
      return this.$store.state.consoleCats
    },
  },
}
</script>

<style scoped lang="sass">
@import '~@assets/style/designs'

$category-circle: 220px
.consoleCategories
	padding: ($base-spacing * 2) 0
	margin-bottom: $section-margin-bottom
	background: $bg-color
	.categoryItem~.categoryItem
		margin-top: $base-spacing
	.circleContainer
		position: relative
		margin: 0 auto
	.circleContainer, .circle, .front, .back
		width: $category-circle
		height: $category-circle
		border-radius: 50%
	.front, .back
		backface-visibility: hidden
		position: absolute
		top: 0
		left: 0
		box-shadow: $base-shadow
		&.hybrid
			background: $c-acc-blue
		&.homeConsoles
			background: $c-acc-red
		&.handheld
			background: $c-acc-green
	.circle
		position: relative
	.front
		img
			max-width: $category-circle - $category-circle * 0.1
			max-height: $category-circle - $category-circle * 0.1
			margin: 0 auto
	.back
		z-index: 2
		.backContainer
			margin: 0 auto
		img
			max-width: 100px
			max-height: 100px
		h3
			display: block
			margin: 0 auto
			color: $c-white

@media (min-width: $md)
	.consoleCategories
		.categoryItem~.categoryItem
			margin-top: 0
		.circleContainer
			perspective: 1000
			&:hover .circle
				transform: rotate3d(45, 45, 0, 180deg)
		.circle
			transition: 0.5s
			transform-style: preserve-3d
			transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860)
		.front
			z-index: 3
		.back
			transform: rotate3d(45,45,0,180deg)
		.front.homeConsoles
			display: block
			text-align: center
</style>
