<script>
import LayWithSidebar from '@layouts/LayWithSidebar'
import LayOneColumn from '@layouts/LayOneColumn'
import { Carousel, Slide } from 'vue-carousel'
import ItemsOverflowedList from '@partials/ItemsOverflowedList'
import SingleConsoleGenerations from '@partials/SingleConsoleGenerations'
import Progress from '@components/Progress'
import ListItem from '@components/ListItem'
import ConsolePageMedia from '@components/ConsolePageMedia'
import { get } from '@services/apiUtils'

export default {
  name: 'SingleConsolePage',
  meta() {
    return {
      title: `${this.title} Page` || 'Consoles Page',
      meta: [
        {
          name: 'description',
          content: this.consola && this.consola.description || `Get the best info regarding ${this.title || 'shown console'}`,
        },
      ],
    }
  },
  components: {
    LayWithSidebar,
    LayOneColumn,
    Carousel,
    Slide,
    ItemsOverflowedList,
    SingleConsoleGenerations,
    Progress,
    ListItem,
    ConsolePageMedia
  },
  data() {
    return {
      consola: null,
      apiUrl: process.env.VUE_APP_API_BASE_URL
    }
  },
  computed: {
    consoles() {
      return this.consola && this.$store.state.consoles.filter(item =>
        this.category === item.cat.alias
      )
    },
    category() {
      return this.consola && this.consola.cat.alias
    },
    categories() {
      return this.$store.state.consoleCats
    },
    alias() {
      return this.$route.params.alias
    },
    title() {
      return this.consola && this.consola.brand[0] && `${this.consola.brand[0].name} ${this.consola.name}`
    }
  },
  watch: {
    alias() {
      this.getConsole()
    }
  },
  created() {
    this.getConsole()
  },
  methods: {
    convertDate(oldDate) {
      let date = new Date(oldDate)
      let day = (date.getDate() !== 1 && date.getDate()) || null
      let month = (date.getMonth() + 1 !== 1 && date.getMonth() + 1) || null
      let year = date.getFullYear()
      let displayDate = []
      day && displayDate.push(day)
      month && displayDate.push(month)
      displayDate.push(year)
      return displayDate.join('/')
    },
    getConsole() {
      get(`${this.apiUrl}/console/${this.alias}/`)
        .then(body => {
            this.consola = body.data
        })
        .catch(_error => {
          this.$router.push({name: 404, params: { message: 'Try another console', title: 'Console', link: `/consoles/home-consoles/handheld` }})
        })
    },
    pricy(price) {
      return price.split('/').join(' | ')
    },
    validDates(dates) {
      dates = Object.entries(this.consola.discontinuedDate)
      let results = []
      for (const [country, value] of dates) {
        (country !== 'last' || country !== 'first') &&
          value &&
          results.push(`${country}: ${this.convertDate(value)}`)
      }
      return (results.length > 1 && results.join('<br>')) || null
    },
  },
}
</script>

<template>
  <div
    :class="['page', 'singleConsolePage']">
    <LayWithSidebar class="topContent">
      <div slot="sidebar">
        <div class="sidebar hidden-xs hidden-md-off">
          <ItemsOverflowedList
            :items="consoles"
            :current-item="consola && consola.name"
            :height="620" />
        </div>
      </div>
      <div
        v-if="consola"
        slot="content">
        <div
          class="mainContent">
          <!-- MAIN CONTENT -->
          <div class="row">
            <div class="jar-xs-100">
              <h1 class="title">
                {{ consola.name }}
                <span
                  v-if="consola.nickname"
                  class="nickname"
                >:: {{ consola.nickname }}</span>
              </h1>
            </div>
          </div>
          <div class="row">
            <div class="details jar-md-50">
              <!-- DETAILS -->
              <div class="characteristics">
                <div class="category characteristic">
                  <div
                    v-for="(category, index) in categories"
                    :key="index">
                    <div
                      v-tooltip="category.name"
                      v-if="category.id === consola.cat.id">
                      <router-link
                        :to="{name:'consoles', params:{cat: consola.cat.alias || 'none'}}"
                      >
                        <img
                          :src="category.photo.url"
                          :alt="category.name"
                          class="categoryIcon" >
                      </router-link>
                    </div>
                  </div>
                </div>
                <ConsolePageMedia :media="consola.media"/>
                <div
                  v-if="consola.bits"
                  class="bits">{{ consola.bits }}</div>
                <div
                  v-tooltip="'Educational'"
                  v-if="consola.educational"
                  class="educational">
                  <BaseIcon
                    class="mediaIcon educationalIcon"
                    icon="microscope"/>
                </div>
              </div>
              <div class="manufacturer heading">
                Manufacturer:
                <router-link
                  :to="{name:'search', params:{input: consola.brand[0].alias || 'none'}}"
                >
                  <span class="bold">{{ consola.brand[0].name }}</span>
                </router-link>
              </div>
              <div class="predecessor heading">
                Predecessor:
                <span class="bold">
                  <span v-if="consola.previous">
                    <router-link
                      :to="{name: 'console', params:{alias: consola.previous.alias}}"
                      class="link"
                    >{{ consola.previous.name }}</router-link>
                  </span>
                  <span v-else>-</span>
                </span>
              </div>
              <div class="successor heading">
                Successor:
                <span class="bold">
                  <span v-if="consola.next">
                    <router-link
                      :to="{name: 'console', params:{alias: consola.next.alias}}"
                      class="link"
                    >{{ consola.next.name }}</router-link>
                  </span>
                  <span v-else>-</span>
                </span>
              </div>
              <div
                v-if="consola.replica"
                class="replica heading">
                Replica of:
                <span class="bold">{{ consola.replica.name || '-' }}</span>
              </div>
              <div
                v-if="consola.releaseDate.first"
                class="date heading">
                Release Date:
                <span
                  v-tooltip="validDates(consola.releaseDate)"
                  class="bold"
                >{{ convertDate(consola.releaseDate.first) }}</span>
              </div>
              <div
                v-if="consola.discontinuedDate.last"
                class="date heading">
                Discontinued Date:
                <span
                  v-tooltip="validDates(consola.disconinuedDate)"
                  class="bold"
                >{{ convertDate(consola.discontinuedDate.last) }}</span>
              </div>
              <div
                class="sizes heading">
                Additional Sizes:
                <span class="bold">{{ consola.sizes || '-' }}</span>
              </div>
              <div
                class="palette heading">
                Display Palette:
                <span class="bold">{{ consola.palette || '-' }}</span>
              </div>
              <div
                class="price heading">
                Initial Price:
                <span class="bold">{{ pricy(consola.price) || '-' }}</span>
              </div>
              <br >
              <div
                v-if="consola.pdf"
                class="pdf">
                <a
                  :href="consola.pdf"
                  target="_blank">
                  <BaseIcon
                    class="pdfIcon"
                    icon="file-pdf" />
                </a>
              </div>
            </div>
            <!-- /.DETAILS -->
            <div class="gallery jar-md-50">
              <Carousel
                :per-page="1"
                :pagination-size="8"
                :pagination-padding="5"
                :pagination-enabled="true"
                pagination-active-color="#51A15C"
              >
                <Slide
                  :title="consola.photo.alt && consola.photo.alt"
                  class="consoleSlide">
                  <div class="sliderImg">
                    <BaseImage
                      :title="consola.photo.alt && consola.photo.alt"
                      :source="consola.photo.thumb ? consola.photo.thumb : ''"
                      :alt="consola.photo.alt && consola.photo.alt"
                      :custom-text="consola.unreleased ? 'UNRELEASED' : ''"
                    />
                  </div>
                </Slide>
                <Slide
                  v-for="(photo, index) in consola.gallery"
                  v-if="index < 8"
                  :key="index"
                  :title="photo.alt"
                  class="consoleSlide"
                >
                  <div class="sliderImg">
                    <img
                      :title="photo.alt"
                      :src="photo.thumb"
                      :alt="photo.alt" >
                  </div>
                </Slide>
              </Carousel>
            </div>
          </div>
        </div>
        <!-- /.MAIN CONTENT -->
        <div
          v-if="consola.sales && consola.cat.bestSales"
          :key="alias"
          class="pies text-xs-center">
          <div class="salesPie">
            <Progress
              :currentVal="consola.sales"
              :maxVal="consola.cat.bestSales"
              :size="400"
              :title="'Sales'"
            />
          </div>
        </div>
      </div>
    </LayWithSidebar>
    <LayOneColumn v-if="consola">
      <div class="row bottomContent">
        <div
          v-if="consola.description"
          class="content jar-md-80">
          <div
            class="description"
            v-html="consola.description" />
        </div>
        <div :class="['generationSidebar', consola.description ? 'jar-md-20' : 'jar-md-100', 'hidden-xs', 'hidden-md-off']">
          <SingleConsoleGenerations
            :current-generation="consola.generation"
            :horizontal="consola.description ? false : true"/>
        </div>
      </div>
      <div class="row">
        <div
          v-if="consola.relatedAccessories"
          class="supportedAccessories jar-xs-100">
          <BaseSectionTitle
            :icon="'○'"
            :icon-accent="'pink'"
            :title="'Supported Accessories'" />
          <Carousel
            :per-page-custom="[[0, 1], [412, 2], [992, 3]]"
            :pagination-size="8"
            :pagination-padding="5"
            :pagination-enabled="true"
            pagination-active-color="#51A15C"
            class="relatedCarousel"
          >
            <Slide
              v-for="(item, index) in consola.relatedAccessories"
              :key="index"
              class="relatedSlide"
            >
              <ListItem :item="item" />
            </Slide>
          </Carousel>
        </div>
      </div>
    </LayOneColumn>
  </div>
</template>

<style lang="sass" scoped>
@import '~@assets/style/designs'

.topContent
  margin-bottom: $section-margin-bottom / 2
.sidebar
  box-shadow: $base-shadow

.title
  margin: 0 0 $base-spacing
  font-size: 33px
  font-weight: bold

.mainContent
  position: relative
  padding: $base-spacing * 2
  overflow: hidden
  background: $ebony-gradient
  border-radius: 3px
  box-shadow: $base-shadow
  &::after
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 1px
    content: ''
    background: $rainbow-gradient

.characteristics
  margin-bottom: $base-spacing * 2
.characteristic
  display: inline-block
.characteristic~.characteristic
  margin-left: $base-spacing


.bits
  position: relative
  display: inline-block
  width: 28px
  height: 28px
  margin: 0 0 0 $base-spacing
  font-family: Tahoma, Geneva, sans-serif
  font-size: 12px
  font-weight: 900
  color: $c-ebony-clay-gray
  text-align: center
  vertical-align: middle
  background: $c-acc-green
  border-radius: 50%
  box-shadow: $base-shadow
  &::after
    position: absolute
    right: 0
    bottom: 3px
    left: 0
    display: block
    margin: 0 auto
    font-size: 8px
    font-weight: 100
    text-align: center
    content: 'bits'

.educational
  display: inline-block
  margin-left: $base-spacing
  color: $c-acc-blue
  &Icon
    width: 23px
    height: 23px


.category
  position: relative
.categoryIcon
  width: auto
  height: 28px

.pdfIcon
  margin-bottom: $base-spacing
  color: $c-acc-pink

.relatedCarousel
  margin: 0 $base-spacing / -2
.relatedSlide
  padding: 0 $base-spacing / 2

.sliderImg
  max-width: 100%
  max-height: 100%
  text-align: center
  img
    max-width: 100%
.heading
  @extend %heading

.description
  color: $text-faded
.pies
  margin-top: $section-margin-top
</style>
