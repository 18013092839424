import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'

import routes from './routes'

Vue.use(VueRouter)

Vue.use(Meta, {
  keyName: 'meta'
})

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    setTimeout(() => {
      return window.scrollTo({ top: 0 })
    }, 10)
  },
})

export default router
