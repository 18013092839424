var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home-content" },
    [
      _c("mainSlideShow"),
      _c(
        "LayWithSidebar",
        { staticClass: "mainContent", attrs: { "sidebar-left": false } },
        [
          _c(
            "div",
            { attrs: { slot: "sidebar" }, slot: "sidebar" },
            [
              _c("BaseSectionTitle", {
                attrs: {
                  "icon-accent": "pink",
                  title:
                    "<span class='accentPink'>Retro</span> Consoles\n        ",
                  icon:
                    "<span style='transform: scale(0.7); display: inline-block'>△</span>"
                }
              }),
              _c("ItemsOverflowedList", {
                attrs: {
                  "max-items": _vm.retroLimit,
                  items: _vm.retroConsoles,
                  height: 800
                }
              })
            ],
            1
          ),
          _vm.consoleNewsPosts && _vm.consoleNewsPosts.length
            ? _c(
                "div",
                { attrs: { slot: "content" }, slot: "content" },
                [
                  _c("BaseSectionTitle", {
                    attrs: {
                      "icon-accent": "blue",
                      title: "Console - <span class='accentBlue'>News</span>",
                      icon:
                        "<span style='transform: scale(0.7); display: inline-block'>&#9447;</span>"
                    }
                  }),
                  _c("articleBlock", {
                    attrs: { items: _vm.consoleNewsPosts, grid: "2" }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c("consoleCategories"),
      _vm.consoleHistoryPosts && _vm.consoleHistoryPosts.length
        ? _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "jar-xs-100" }, [
                _c(
                  "div",
                  { staticClass: "consoleNews" },
                  [
                    _c("BaseSectionTitle", {
                      attrs: {
                        "icon-accent": "green",
                        title:
                          "Console - <span class='accentGreen'>History</span>",
                        icon:
                          "<span style='transform: scale(0.7); display: inline-block'>△</span>"
                      }
                    }),
                    _c(
                      "Carousel",
                      {
                        staticClass: "newsCarousel",
                        attrs: {
                          "per-page-custom": [[0, 1], [776, 2], [992, 3]],
                          "pagination-size": 8,
                          "pagination-padding": 5,
                          "pagination-enabled": true,
                          "pagination-active-color": "#51A15C"
                        }
                      },
                      _vm._l(_vm.consoleHistoryPosts, function(item, index) {
                        return _c(
                          "Slide",
                          { key: index + "0", staticClass: "newsSlide" },
                          [
                            _c("articleTile", {
                              attrs: { item: item, excerpt: 0 }
                            })
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }