var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeFilters,
          expression: "closeFilters"
        }
      ],
      staticClass: "sidebar",
      class: { active: _vm.$store.state.isFilterOpen }
    },
    [
      _c(
        "div",
        { on: { click: _vm.toggleFilters } },
        [
          _c("BaseIcon", {
            staticClass: "openFiltersBtn",
            attrs: { icon: "chevron-down" }
          })
        ],
        1
      ),
      _c("h2", { staticClass: "text-xs-center title" }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _vm._t("default"),
      _c(
        "div",
        {
          staticClass: "text-xs-right",
          on: {
            click: function($event) {
              return _vm.clearFilters()
            }
          }
        },
        [
          _c("BaseButton", {
            staticClass: "clearBtn",
            attrs: { text: "CLEAR" }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }