<template>
  <div
    :class="{active : searchFocused}"
    class="footerSearch text-xs-center">
    <div
      :class="{enter : enter}"
      class="searchInput">
      <input
        id="footerSearch"
        ref="footerSearchInput"
        v-model="searchText"
        type="text"
        @focus="searchFocused = true"
        @blur="searchFocused = false"
        @input="enableEnter"
        @keyup.enter="enterSearch"
      >
      <div
        class="enterIcon"
        @click="enterSearch">Enter</div>
    </div>
    <label for="footerSearch">
      <BaseIcon
        :class="['searchIcon', {active : iconShown}]"
        icon="search"/>
    </label>
  </div>
</template>

<script>
export default {
  name: 'FooterSearch',
  data: function() {
    return {
      searchText: '',
      searchFocused: false,
      enter: false,
      iconShown: true,
    }
  },
  methods: {
    enableEnter() {
      if (this.searchText.length > 0) {
        this.enter = true
        this.iconShown = false
      } else {
        this.enter = false
        this.iconShown = true
      }
    },
    enterSearch() {
      if (this.searchText.length > 3) {
        this.$router.push({
          name: 'search',
          params: { input: this.searchText },
        })
        this.searchText = ''
        this.enter = false
        this.iconShown = true
      } else {
        this.$toast.warn({
          title: 'Search Warning',
          message: 'You have to add at least 4 characters',
          timeOut: 7000,
          position: 'top right',
          progressBar: true,
        })
      }
    },
  },
}
</script>

<style scoped lang="sass">
@import '~@assets/style/designs'

.searchInput
  position: relative
  display: inline-block
  width: 100%
  border-bottom: 1px solid $text-muted
  transition: border-bottom-color $prim-trans-time $prim-trans-easing
  input
    width: 100%
    font-size: 1rem
    color: $c-alto-grey
  .enterIcon
    position: absolute
    right: 0
    bottom: 7px
    padding: 5px
    line-height: 1
    cursor: pointer
    border: 1px solid $text-muted
    border-radius: $base-border-radius
    opacity: 0
    transition: opacity $prim-trans-time * 2 $prim-trans-easing
  &.enter
    .enterIcon
      z-index: 10
      opacity: 1
.searchIcon
  position: absolute
  top: 0
  right: 0
  font-size: 1.2rem
  cursor: pointer
  opacity: 0
  transition: opacity $prim-trans-time * 2 $prim-trans-easing, color $prim-trans-time $prim-trans-easing
  &.active
    z-index: 0
    opacity: 1
.footerSearch
  position: relative
  max-width: 200px
  margin: $base-spacing * 2 auto
  color: $text-muted
  &.active
    .searchInput
      border-bottom-color: $c-alto-grey
    .searchIcon
      color: $c-alto-grey

@media (min-width: $md)
  .footerSearch
    margin: 0 auto
</style>
