<template>
  <div
    :style="'background-image: url(' + slideshowBg + ');'"
    class="slideshow">
    <div class="slideshowInner">
      <carousel
        :navigate-to="1"
        :per-page="1"
        :autoplay="true"
        :autoplay-timeout="7000"
        :autoplay-hover-pause="true"
        :pagination-size="8"
        :pagination-padding="5"
        :pagination-enabled="true"
        pagination-active-color="#51A15C"
        pagination-position="bottom-overlay"
        class="homepageCarousel"
      >
        <slide
          v-for="slide in slides"
          :key="slide.index"
          class="slide">
          <div class="container">
            <div class="row v-align-sm">
              <div class="jar-sm-60 jar-sm-push-40 slide-content">
                <h2 class="slideTitle">{{ slide.name }}</h2>
                <div
                  class="description"
                  v-html="slide.descr"/>
                <div class="text-md-right">
                  <BaseButton
                    :text="'READ MORE'"
                    :link="slide.link"
                    class="accentGreenBlue"/>
                </div>
              </div>
              <div class="jar-sm-40 jar-sm-pull-60 text-xs-center">
                <div class="slideImg v-align-sm">
                  <BaseImage
                    :source="slide.photo.url"
                    :alt="slide.photo.alt"/>
                </div>
              </div>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'MainSlideShow',
  components: {
    Carousel,
    Slide,
  },
  computed: {
    slides() {
      return this.$store.state.slideshows
    },
    slideshowBg() {
      return this.$store.state.slideshowBg
    },
  },
  created() {
    this.$store.dispatch('loadSlideshow')
  },
}
</script>

<style scoped lang="sass">
@import '~@assets/style/designs'

.slideshow
  width: 100%
  background-repeat: no-repeat
  background-position: top center
  background-size: cover
  .slideshowInner
    height: 100%
    .slideTitle
      position: relative
      padding: $base-spacing 0
      margin-bottom: $base-spacing
      &::after
        position: absolute
        bottom: -5px
        left: 0
        width: 80px
        height: 2px
        content: ""
        background: linear-gradient(90deg, $c-acc-green, $c-acc-green, $base-color-light)
    .slideImg
      position: relative
      padding: 0 $base-spacing
      margin-top: $base-spacing
      margin-bottom: $base-spacing * 3

      img
        position: relative
        z-index: 1
        max-width: 100%
        max-height: 280px
        margin: 0 auto
.description
  margin-bottom: $base-spacing

@media (min-width: $sm)
  .slideshow
    height: 500px
    .slideshowInner
      .slide-content
        padding-left: $base-spacing * 2
      .slideTitle
        min-height: auto
      .slideImg
        height: 500px
        margin-top: 0
        margin-bottom: 0
        &::before
          position: absolute
          top: 0
          left: 0
          z-index: -1
          width: 100%
          height: 100%
          content: ""
          background: $bg-color
          opacity: 0.5
        img
          max-height: 318px
</style>

<style lang="sass">
@import '~@assets/style/designs'

// Carousel
.homepageCarousel
  /* stylelint-disable selector-class-pattern */
  .VueCarousel-pagination
    bottom: 15px
</style>
