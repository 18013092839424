var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row articleBlock" },
    _vm._l(_vm.items, function(item, index) {
      return _c(
        "div",
        { key: index, class: [_vm.gridCount, "gridItem"] },
        [_c("articleTile", { attrs: { item: item } })],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }