<template>
  <div class="mainNavbar">
    <div class="container">
      <div class="navbarContent">
        <mainMenu/>
        <Logo class="logo"/>
        <mainSearch class="search"/>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@components/Logo'
import mainMenu from '@components/MainMenu'
import mainSearch from '@components/MainSearch'
import SocialMedia from '@components/SocialMedia'

export default {
  name: 'TheNavbar',
  components: {
    Logo,
    mainMenu,
    mainSearch,
    SocialMedia,
  },
}
</script>

<style scoped lang="sass">
@import '~@assets/style/designs'

.mainNavbar
  position: fixed
  top: 0
  z-index: 100
  width: 100%
  height: $header-height
  background: $bg-color
  box-shadow: 0 0 10px 0 black
.navbarContent
  display: flex
  justify-content: space-between
.logo
  z-index: 100
.search
  z-index: 100
  margin-left: $base-spacing
</style>
