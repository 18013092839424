var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "mainMenuConsoleCarouse",
        "consolesCarousel",
        { active: _vm.$store.state.isNavOpen }
      ]
    },
    [
      _c("h2", { staticClass: "sliderHeader" }, [_vm._v("Latest Releases")]),
      _c(
        "carousel",
        {
          staticClass: "menuCarousel",
          attrs: {
            "navigate-to": 1,
            "per-page": 1,
            autoplay: true,
            "autoplay-timeout": 7000,
            "autoplay-hover-pause": true,
            "pagination-enabled": false,
            "center-mode": true,
            loop: true,
            "navigation-enabled": true,
            "navigation-click-target-size": -100,
            "pagination-active-color": "#212733"
          }
        },
        _vm._l(_vm.consoles, function(consola, index) {
          return index < 6
            ? _c(
                "slide",
                { key: consola.id, staticClass: "menuSlide" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "console",
                          params: { alias: consola.alias }
                        }
                      }
                    },
                    [
                      _c("BaseImage", {
                        staticClass: "sliderImage",
                        attrs: {
                          source: consola.photo.thumb,
                          alt: consola.photo.alt
                        }
                      }),
                      _c("br"),
                      _c("h3", { staticClass: "sliderTitle" }, [
                        _vm._v(
                          _vm._s(consola.brand) + " " + _vm._s(consola.name)
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }