<template>
  <div :class="['mainMenuConsoleCarouse', 'consolesCarousel', { active: $store.state.isNavOpen}]">
    <h2 class="sliderHeader">Latest Releases</h2>
    <carousel
      :navigate-to="1"
      :per-page="1"
      :autoplay="true"
      :autoplay-timeout="7000"
      :autoplay-hover-pause="true"
      :pagination-enabled="false"
      :center-mode="true"
      :loop="true"
      :navigation-enabled="true"
      :navigation-click-target-size="-100"
      pagination-active-color="#212733"
      class="menuCarousel"
    >
      <slide
        v-for="(consola, index) in consoles"
        v-if="index < 6"
        :key="consola.id"
        class="menuSlide"
      >
        <router-link :to="{name: 'console', params: {alias: consola.alias}}">
          <BaseImage
            :source="consola.photo.thumb"
            :alt="consola.photo.alt"
            class="sliderImage"/>
          <br>
          <h3 class="sliderTitle">{{ consola.brand }} {{ consola.name }}</h3>
        </router-link>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'MainMenuSlider',
  components: {
    Carousel,
    Slide,
  },
  computed: {
    consoles() {
      return this.$store.getters.consolesLatestReleased
    },
  },
}
</script>

<style lang="sass">
/* stylelint-disable selector-class-pattern */
.mainMenuConsoleCarouse .VueCarousel-navigation-button
  font-size: 1.2rem
  font-weight: bold
  color: white !important
.mainMenuConsoleCarouse .VueCarousel-navigation-prev
  left: 1.8rem !important
.mainMenuConsoleCarouse .VueCarousel-navigation-next
  right: 1.8rem !important
/* stylelint-enable */
</style>


<style scoped lang="sass">
@import '~@assets/style/designs'
$border-color: transparentize($c-ebony-clay-gray, 0.8)

.sliderHeader
  margin: 0
  text-align: center
// Carousel
.consolesCarousel
  position: absolute
  top: 50%
  display: none
  width: 100%
  opacity: 0
  transition: opacity 2s ease-in-out
  transform: translateY(-50%)
  &.active
    opacity: 1
.sliderImage
  display: block
  max-width: 100%
  margin: 0 auto
  opacity: 0.7
  transition: opacity .3s ease-in-out
.menuSlide
  position: relative
  display: block
  padding: $base-spacing
  text-align: center
  &::before
    position: absolute
    top: 0
    left: 0
    display: block
    width: 100%
    height: 100%
    content: ''
    background: $border-color
    opacity: 0
    transition: opacity .3s ease-in-out
  &:hover
    &::before
      opacity: 1
.sliderTitle
  display: inline-block
  padding: 5px 15px
  margin-bottom: $base-spacing
  color: $c-ebony-clay-gray
  border-radius: 10px
</style>
