export function getByKeyword(list, keyword) {
  const search = new RegExp(keyword, 'gi')
  return list.filter(item => {
    return item.name.match(search) || item.brand.match(search)
  })
}

export function getByCategory(list, category) {
  if (!category) return list
  return list.filter(
    item => item.cat.alias === category
  )
}

export function getByGeneration(list, generation) {
  if (!generation) return list
  return list.filter(item => item.generation === generation)
}

export function getByBrand(list, brand) {
  if (!brand) return list
  return list.filter(
    item => item.brand.toLowerCase().replace(' ', '-') === brand
  )
}

export function getByMedium(list, medium) {
  if (!medium) return list
  return list.filter(
    item => item.media.toLowerCase().replace(' ', '-') === medium
  )
}
