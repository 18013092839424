<script>
export default {
  name: 'SingleConsoleGenerations',
  props: {
    currentGeneration: {
      type: String,
      default: '',
    },
    horizontal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    generations() {
      return this.$store.state.generations
    },
  },
  created() {
    this.$store.dispatch('loadGenerations')
  },
}
</script>

<template>
  <div :class="['generations', 'text-xs-center', horizontal && 'horizontal']">
    <div class="generationsContainer">
      <div
        v-for="generation in generations"
        :class="['generationsItem', {active : generation.name === currentGeneration}]"
        :key="generation.name"
      >
        <div>
          <div class="name heading">
            {{ generation.name }}
            <div v-if="generation.name === currentGeneration">Generation</div>
          </div>
          <div class="years heading">{{ generation.startYear }} - {{ generation.endYear }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~@assets/style/designs'

.generations
  &Container
    position: relative
    &::before
      position: absolute
      top: 0
      right: 0
      left: 0
      display: inline-block
      width: 5px
      height: 100%
      margin: auto
      content: ''
      background: $bg-color
  &Item
    position: relative
    z-index: 10
    display: flex
    align-items: center
    justify-content: center
    width: 100px
    height: 100px
    margin: 0 auto 50px
    color: $text-faded
    background: $bg-color
    border-radius: 50%
    box-shadow: $base-shadow
    &.active
      width: 140px
      height: 140px
      color: $c-alto-grey
      background: darken($c-acc-green, 30%)
      border: 10px solid $c-acc-green
    .name
      margin-bottom: 5px
      font-size: 18px
      font-weight: bold
    .years
      font-size: 11px
      font-weight: bold
  &.horizontal
    margin-bottom: $section-spacing
    .generations
      &Container
        display: flex
        align-items: center
        justify-content: center
        &::before
          top: 50%
          right: unset
          left: $base-spacing
          width: calc(100% - #{$base-spacing * 2})
          height: 5px
      &Item
        margin: 0 auto
</style>
