var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["page", "singleAccessoryPage"] },
    [
      _c("LayOneColumn", [
        _c("div", [
          _vm.accessory
            ? _c("div", { staticClass: "mainContent topContent" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "details jar-md-50" }, [
                    _c("h1", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.accessory.name))
                    ]),
                    _c(
                      "div",
                      { staticClass: "manufacturer heading" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "search",
                                params: {
                                  input: _vm.accessory.brand.name || "none"
                                }
                              }
                            }
                          },
                          [
                            _vm._v("Manufacturer:\n                "),
                            _c("span", { staticClass: "head" }, [
                              _vm._v(_vm._s(_vm.accessory.brand.name))
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "category" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "accessories",
                                params: {
                                  cat: _vm.accessory.cat.alias || "none"
                                }
                              }
                            }
                          },
                          [
                            _c(
                              "BaseLabel",
                              {
                                staticClass: "label",
                                attrs: {
                                  color: "green",
                                  size: "medium",
                                  spacing: "12px 0"
                                }
                              },
                              [_vm._v(_vm._s(_vm.accessory.cat.name))]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "gallery jar-md-50" },
                    [
                      _c(
                        "Carousel",
                        {
                          attrs: {
                            "per-page": 1,
                            "pagination-size": 8,
                            "pagination-padding": 5,
                            "pagination-enabled": true,
                            "pagination-active-color": "#51A15C"
                          }
                        },
                        [
                          _c(
                            "Slide",
                            {
                              staticClass: "consoleSlide",
                              attrs: { title: _vm.accessory.photo.alt }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "sliderImg" },
                                [
                                  _c("BaseImage", {
                                    attrs: {
                                      title: _vm.accessory.photo.alt,
                                      source: _vm.accessory.photo.thumb,
                                      alt: _vm.accessory.photo.alt
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._l(_vm.accessory.gallery, function(photo, index) {
                            return index < 8
                              ? _c(
                                  "Slide",
                                  {
                                    key: index,
                                    staticClass: "consoleSlide",
                                    attrs: { title: photo.alt }
                                  },
                                  [
                                    _c("div", { staticClass: "sliderImg" }, [
                                      _c("img", {
                                        attrs: {
                                          title: photo.alt,
                                          src: photo.thumb,
                                          alt: photo.alt
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "row bottomContent" }, [
          _c("div", { staticClass: "content jar-xs-100" }, [
            _vm.accessory.description
              ? _c("div", {
                  staticClass: "description",
                  domProps: { innerHTML: _vm._s(_vm.accessory.description) }
                })
              : _vm._e()
          ]),
          _c("br"),
          _c("br"),
          _c(
            "div",
            { staticClass: "supportedConsoles jar-xs-100" },
            [
              _c("BaseSectionTitle", {
                attrs: {
                  icon: "○",
                  "icon-accent": "pink",
                  title: "Supported Consoles"
                }
              }),
              _c(
                "Carousel",
                {
                  staticClass: "relatedCarousel",
                  attrs: {
                    "per-page-custom": [[0, 1], [412, 2], [768, 3], [992, 4]],
                    "pagination-size": 8,
                    "pagination-padding": 5,
                    "pagination-enabled": true,
                    "pagination-active-color": "#51A15C"
                  }
                },
                _vm._l(_vm.accessory.relatedConsoles, function(item, index) {
                  return _c(
                    "Slide",
                    { key: index, staticClass: "relatedSlide" },
                    [_c("ListItem", { attrs: { item: item } })],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }