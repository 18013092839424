<script>
import LazyLoad from '@directives/LazyLoad'

export default {
  name: 'BaseImage',
  directives: {
    lazyLoad: LazyLoad,
  },
  props: {
    source: {
      type: String,
      default: '',
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    noImageTitle: {
      type: String,
      default: 'NO IMAGE'
    },
    customText: {
      type: String,
      default: ''
    },
    background: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <div
    v-if="background"
    :style="`background-image: url('${source}')`"
    class="background"/>

  <figure
    v-lazyload
    v-else-if="!background && source"
    :key="source"
    :class="['wrap']">
    <div class="spinner"/>
    <img
      :data-url="source"
      :alt="alt"
      class="item">
  </figure>
  <div
    v-else
    class="noImage">
    <BaseIcon
      :icon="'image'"
      class="noImageIcon"/>
    <BaseLabel
      class="label"
      float>{{ customText || 'NO IMAGE' }}</BaseLabel>
  </div>


</template>

<style scoped lang="sass">
@import '~@assets/style/designs'

.item
  display: block
  max-width: 100%
  max-height: 100%
  margin: 0 auto
  opacity: 0
  transition: opacity .6s ease-in-out
.spinner
  opacity: 0.5
  transition: opacity .6s ease-in-out
  &::before
    position: absolute
    top: 0
    left: 0
    display: block
    width: 100%
    height: 100%
    content: ''
    background: $green-blue-purple-gradient
    animation: fade 2s ease-in-out infinite alternate-reverse
.wrap
  display: flex
  align-items: center
  width: 100%
  max-height: 100%
  margin: 0
  text-align: center
  &.loaded
    .item
      opacity: 1
    .spinner
      opacity: 0
.noImage
  position: relative
  display: inline-block
  &Icon
    width: 150px
    max-width: 100%
    height: 100%
    opacity: 0.5
  .label
    margin-top: $base-spacing
.background
  position: absolute
  top: 0
  right: 0
  left: 0
  height: 100%
  background-position: center
  background-size: cover


@keyframes fade
  from
    opacity: 0.3
  to
    opacity: 0.7
</style>
