var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "figure",
    {
      staticClass: "progress",
      style: "width:" + (_vm.getCircleSize * 2 + _vm.width * 2) + "px"
    },
    [
      _vm.size
        ? _c(
            "svg",
            {
              staticClass: "circleChart",
              attrs: {
                viewbox: [
                  0,
                  0,
                  _vm.getCircleSize * 2 + _vm.width,
                  _vm.getCircleSize * 2 + _vm.width
                ],
                width: _vm.getCircleSize * 2 + _vm.width * 2,
                height: _vm.getCircleSize * 2 + _vm.width * 2,
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("circle", {
                class: ["circleChartBackground", _vm.theme],
                attrs: {
                  r: _vm.getCircleSize,
                  cx: _vm.getCircleSize + _vm.width,
                  cy: _vm.getCircleSize + _vm.width,
                  "stroke-width": _vm.width,
                  fill: "none"
                }
              }),
              _c("circle", {
                class: ["circleChartSlide", _vm.theme],
                attrs: {
                  r: _vm.getCircleSize,
                  cx: _vm.getCircleSize + _vm.width,
                  cy: _vm.getCircleSize + _vm.width,
                  "stroke-dasharray": [_vm.val, _vm.size],
                  "stroke-width": _vm.width,
                  fill: "none"
                }
              })
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "content" }, [
        _vm._v(_vm._s(_vm._f("counting")(_vm.currentVal)))
      ]),
      _c("h3", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }